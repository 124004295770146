@import "~assets/scss/settings";

.wrapper {
  height: 100%;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}

.background {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  &:after {
    content: " ";
    position: absolute;
    bottom: -1px;
    left: 0;
    opacity: 1;
    width: 784px;
    height: 488px;
    background-image: url('./images/corner.svg');
  }
}

.logo {
  background-color: color(green-dark);
  @include p(5);
  @include mb(5);
  margin-left: 112px;
}

.header {
  color: color(white);
  text-transform: uppercase;
  @include mt(5);
  font-size: 50px;
  line-height: 1.06;
  font-family: $themeFontBold;
  font-weight: normal;

  :global(.bold) {
    font-weight: 700;
  }

  @include grid-media($touchtable) {
    font-size: 225px !important;
    margin-top: 300px;
  }

  &Wrapper {
    position: absolute;
    bottom: 92px;
    left: 0;
  }
}

.content {
  position: relative;
  z-index: 2;
  height: 100%;
  //max-width: 80vw !important;
  .bottom {
    position: absolute;
    bottom: 140px;
    left: 0;
    right: 0;

    :global(.ta-btn) {
      margin-left: auto;
      margin-right: 0;

      @include grid-media($touchtable) {
        height: 120px;
        width: 460px;
        max-width: none;
        font-family: "RobotSlab",sans-serif;
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 4px;
      }
    }
  }
}

.splashBttn {
  background-color: color(white) !important;
  border-color: color(white) !important;
}

.shape {
  position: absolute;
  bottom: 0px;
  left: 400px;
  background-image: url('./images/shape.svg');
  background-repeat: no-repeat;
  width: 220px;
  height: 332px;
}
