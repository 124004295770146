@import "~traffic-property-app/assets/scss/normalize";
@import "~traffic-property-app/assets/scss/settings";
@import "~traffic-property-app/assets/scss/typography";
@import "~traffic-property-app/assets/scss/layout";

@import "./layout.scss";
@import "./components/buttons.scss";
@import "./components/lfs.scss";
@import "./components/filter.scss";
@import "./components/select.scss";
@import "./components/results.scss";
@import "./components/packages.scss";
@import "./components/packagedetail.scss";
@import "./components/masterplan.scss";
@import "./components/packagelist.scss";

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-land-for-sale .lfs--map a {
  .lfs--map--legend span:before {
    background: #3ba4a7;
  }
}

.page-earlwood-way {
  #traffic-app {
    display: none;
  }
}

.lfs--map {
  // a:not(.builder):not(.hold):not(.hold) {
  //   polygon {
  //     fill: rgba(143, 206, 201, 0.65);
  //   }
  // }

  polygon {
    &.builder {
      // fill: rgba(color(builders), 0.5);
      fill: rgba(192,169,209,.75);
    }
    &.sold {
      fill: rgba(color(sold), 0.5);
    }
  }
}

body {
  .enquiryform {
    background: #ba0c2f;

    h2,
    h2 span,
    h3 {
      color: #fff;
    }
  }
}
