@import '~assets/scss/settings';

.wrapper {
  .sidebar_footer {
    bottom: 13vh;
  }

  .sidebar {
    padding: 60px 40px 20px 40px;
    box-shadow: -7px 0px 5px 0px rgba(0, 0, 0, 0.1);

    @include grid-media($touchtable) {
      background-color: color(grey1);
      padding: 25px 25px;
      border-radius: 5px;
    }

    .title {
      position: relative;
      color: color(orange);
      font-size: 42px;
      font-family: 'Verlag', sans-serif;
      text-transform: uppercase;
      margin-bottom: 15px;

      @include grid-media($touchtable) {
        color: color(black);
        font-size: 22px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: 'Gotham', sans-serif;
        font-weight: 300;
      }

      .reset {
        position: absolute;
        right: 0;
        top: 8px;

        @include grid-media($touchtable) {
          top: 0;
        }
      }
    }

    .subtitle {
      font-family: 'RobotoSlab', sans-serif;
      font-size: 20px;
      letter-spacing: 0.15px;
      line-height: 26px;

      @include grid-media($touchtable) {
        display: none;
      }
    }

    .listgroup {
      margin-top: 160px;
      padding: 0;

      @include grid-media($touchtable) {
        margin-top: 20px;
        margin-bottom: 0px;
      }

      li {
        list-style-type: none;
        transition: 0.5s ease all;
        padding: 18px 20px;
        border-bottom: 1px color(grey3) solid;
        margin-top: 8px;

        &.amenitieslink {
          padding: 0;

          a {
            display: block;
            padding: 18px 20px;
          }
        }

        &:first-child {
          border-top: 1px color(grey3) solid;
        }

        &.active {
          background-color: #effffe;
        }
      }
    }

    a {
      color: color(black);
    }
  }

  .color {
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    @include mr(3);
  }

  .map {
    position: absolute;
    left: 0;
    background-position: top center;
    background-repeat: no-repeat;
    // background-size: 100% auto;
    width: 1280px;
    height: 1080px;

    @include grid-media($touchtable) {
      left: auto;
    }
  }
}
