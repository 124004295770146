// - - - - - - - - - - - - - - - - -
// Grid
// - - - - - - - - - - - - - - - - -

@mixin layout-s-left {
  width: 400px;
  padding: 0 40px 0 0;
}

@mixin layout-s-right {
  width: calc(100% - 448px);
}

@mixin layout-m-left {
  width: 470px;
  padding: 0 40px 0 0;
}

@mixin layout-m-right {
  width: calc(100% - 518px);
}

// - - - - - - - - - - - - - - - - -
// colors
// - - - - - - - - - - - - - - - - -

@function color($color-name, $color-variant: null) {
  @if ($color-variant != null) {
    @return map-get(map-get($colors, $color-name), $color-variant);
  } @else {
    @return map-get($colors, $color-name);
  }
}

// - - - - - - - - - - - - - - - - -
// px to em calc
// - - - - - - - - - - - - - - - - -

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

@function rem($pixels, $context: $browser-context) {
  @return #{$pixels/$context}rem;
}
