@import "~assets/scss/settings";

.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: color(white);
  padding: 0 40px 40px;
  visibility: hidden;

  .content {
    position: absolute;
    opacity: 0;
    transition: 0.5s ease all;
    width: 400px;
    text-align: center;
    background: #fff;
    margin: 30px 0;
  }

  .paragraph {
    font-size: 15px;
    max-width: 240px;
    margin: auto;
    margin-bottom: 50px;
  }

  &.show {
    visibility: visible;

    .content {
      opacity: 1;
    }
  }

  .qrcode {
    display: block;
  }

  .sendEmail,
  .close {
    position: relative;
    display: block;
    top: unset;
    left: unset;
    text-align: center;
    float: unset;
    text-decoration: underline;
    font-size: 14px;
    text-transform: uppercase;
  }

  .sendEmail {
    margin-top: 10px;

    &:after {
      left: calc(50% - 125px);
      width: 249px;
    }
  }

  .close {
    margin-top: 15px;

    &:after {
      left: calc(50% - 32px);
      width: 62px;
    }
  }
}
