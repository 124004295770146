@import '~assets/scss/settings';

.map,
.basemap,
.surface,
.north,
.overview {
  position: absolute;
  z-index: 1;
  width: 1280px;
  height: 1080px;
  background-size: 100% auto;
  background-position: center center;
  overflow: hidden;
}

.melbcbd,
.cranbourne {
  position: fixed;
  z-index: 4;
  bottom: 50px;
  width: 80px;
  height: auto;

  @include grid-media($touchtable) {
    width: auto;
    bottom: 100px;
    height: auto;
  }
}

.melbcbd {
  left: 50px;

  @include grid-media($touchtable) {
    left: 80px;
  }
}

.cranbourne {
  right: 690px;

  @include grid-media($touchtable) {
    right: 80px;
  }
}

.overview {
  z-index: 5;
  pointer-events: none;
}

.north {
  z-index: 5;
  left: -620px;
  pointer-events: none;

  @include grid-media($touchtable) {
    left: 0;
  }
}

.basemap {
  left: 0;
  top: 0;
  transform: scale(1);
  transition: all 0.3s ease-in;

  @include grid-media($touchtable) {
    left: 0;
    z-index: 3;
  }
}

.youarehere {
  display: block;
  position: absolute;
  z-index: 2;
  left: 54.5%;
  top: 48%;
  transform: scale(0.65);

  @include grid-media($touchtable) {
    left: 55.5%;
    transform: scale(1);
    top: 50%;
  }
}

.surface {
  opacity: 0;
  z-index: 0;

  &.active {
    z-index: 1;
    opacity: 0;
    // transition: 0.3s ease opacity;
    animation: glow 1.5s forwards;

    @keyframes glow {
      0% {
        opacity: 0;
      }

      40% {
        opacity: 1;
      }

      66.66% {
        opacity: 0.7;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &:nth-child(2) {
    &.active {
      animation-delay: 1.5s;
    }
  }

  &:nth-child(3) {
    &.active {
      animation-delay: 3s;
    }
  }
}

.level {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  // display: none;
  visibility: hidden;

  &.active {
    visibility: visible;
  }
}

.labels {
  flex-direction: row !important;
  position: absolute !important;
  bottom: 0;
  @include ml(4);
  @include mb(4);
  @include px(2);

  .label {
    display: inline-block;
    @include p(2);

    .circle {
      vertical-align: middle;
      display: inline-block;
      border-radius: 50%;
      width: 42px;
      height: 42px;
    }

    .name {
      vertical-align: middle;
      font-family: 'Verlag', sans-serif;
      @include pl(2);
    }
  }
}

.galleryWrapper {
  display: block;
  // width: calc(100vw - 640px);
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;

  @include grid-media($touchtable) {
    width: 100vw;
    height: 100vh;
  }
}

.markers {
  position: fixed;
  top: 0;
  left: 0;
  width: 1280px;
  height: 1080px;
  z-index: 5;
}

.marker {
  &__gallery {
    display: block;
    position: absolute;
    right: -80px;
    top: -15px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s 0s ease;
    width: 70px;

    @include grid-media($touchtable) {
      width: auto;
      right: -130px;
      top: -30px;
    }

    .active & {
      visibility: visible;
      opacity: 1;
      transition: all 0.25s 0.5s ease;
    }
  }

  &__item {
    position: absolute;
    text-align: center;
    z-index: 1;
    visibility: hidden;
    background-color: color(green-dark);
    border-radius: 5px;
    padding: 12px 20px;

    .active & {
      visibility: visible;
    }

    &:nth-child(2) {
      .marker__title,
      .marker__subtitle,
      .marker__gallery {
        .active & {
          transition-delay: 2s;
        }
      }

      .marker__line {
        .active & {
          animation-delay: 2.5s;
        }

        &:after {
          .active & {
            transition-delay: 3.25s;
          }
        }
      }
    }

    &:nth-child(3) {
      .marker__title,
      .marker__subtitle,
      .marker__gallery {
        .active & {
          transition-delay: 3.5s;
        }
      }

      .marker__line {
        .active & {
          animation-delay: 4s;
        }

        &:after {
          .active & {
            transition-delay: 5s;
          }
        }
      }
    }
  }

  &__title,
  &__subtitle {
    display: block;
    color: $white;
    font-family: $font-family-sans-serif;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.25s 0s ease;

    .active & {
      opacity: 1;
      transform: translateY(0);
      transition: all 0.5s 0.5s ease;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.75px;
    line-height: 26px;
    max-width: 280px;
    font-variant-numeric: lining-nums;

    @include grid-media($touchtable) {
      font-size: 34px;
      font-weight: 700;
      letter-spacing: -0.44px;
      line-height: 34px;
      max-width: 370px;
    }
  }

  &__subtitle {
    font-size: 11px;
    font-weight: 300;
    font-family: 'Gotham Light', sans-serif;

    @include grid-media($touchtable) {
      font-size: 16px;
      font-weight: 300;
    }
  }

  &__line {
    position: absolute;
    height: 12vh;
    width: 2px;
    bottom: -12vh;
    background: color(green-dark);
    transform-origin: top center;
    transform: scaleY(0);

    @include grid-media($touchtable) {
      height: 15vh;
      bottom: -16.5vh;
    }

    .active & {
      animation: grow 1s 1s forwards;
    }

    &:after {
      display: block;
      content: ' ';
      position: absolute;
      bottom: -7px;
      left: -3px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px transparent solid;
      opacity: 0;

      .active & {
        opacity: 1;
        transition: opacity 0.5s 1.75s ease;
      }
    }

    &.short {
      height: 6vh;
      bottom: -6vh;
    }

    &.long {
      height: 15vh;
      bottom: -15vh;
    }
  }

  @keyframes grow {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
}
