@import "~assets/scss/settings";

.sidebar {
  position: fixed;
  width: 480px;
  top: 0;
  right: 160px;
  bottom: 0;
  z-index: 1;
  background: color(white);
  padding-top: 25px;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;

  @include grid-media($touchtable) {
    top: unset;
    bottom: calc(13vh + 207px);
    right: 4%;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 35px;
  }
}