@include grid-media($xxxlarge) {
  .lfs {
    &.isLot {
      .lfs--nav--scroll {
        display: block !important;
        overflow: hidden;
      }
    }

    &.ta-no-lfs-header .ta-filter--heading h2 {
      visibility: hidden;
    }

    &--nav {
      position: absolute;
      z-index: 1;
      left: 1280px;
      top: 0;
      width: 480px;
      height: 100vh;
      margin-left: 0;
      padding: 40px 40px 40px;
      box-shadow: 0 -15px 20px rgba(0, 0, 0, 0.2);

      h2 button,
      &--scroll {
        display: none !important;
      }

      h2 {
        // color: transparent;
        width: 100%;
        border: none;
        margin-top: 74px;
        font-family: "brandon-grotesque", sans-serif;
        color: color(green-dark);
        text-transform: none;
        font-size: 16px;
        font-weight: 700;

        &:after {
          content: "Land for Sale";
          display: block;
          font-family: "brandon-grotesque", sans-serif;
          font-size: 48px;
          letter-spacing: -0.44px;
          margin-top: -76px;

          text-transform: uppercase;
          font-weight: 700;
          color: color(green-dark);
        }
      }

      &--filter {
        padding-top: 140px;
      }
    }

    &--c2as {
      display: none;
    }

    > .ta-container {
      @include grid-collapse;
    }

    .socialsharing {
      display: none;
    }

    &--map {
      &:not([class*="acacia"]) {
        .legend {
          // &__hold,
          // &__builders,
          // &__sold {
          //   display: none;
          // }

          &__available {
            &:before {
              background: rgba(224,150,82, 1);
            }
          }
          &__hold {
            &:before {
              background: rgba(232,100,109, 1);
            }
          }
          &__builders {
            &:before {
              background: rgba(192, 169,209, 1);
            }
          }
          &__sold {
            &:before {
              background: rgba(168,73,137, 1);
            }
          }
          &__coming_soon {
            &:before {
              background: rgba(114,128,190, 1);
            }
          }
        }
      }

      &:not(.no-nav) {
        position: absolute;
        top: 0;
        left: 80px;
        width: 1200px;
        height: 100vh;
        margin-left: 0;
      }

      .legend,
      &--legend {
        right: auto;
        left: 20px;
        bottom: 20px;

        &__available {
          &::before {
            // background-color: rgba(166,113,175,1);
            background-color: rgba(color(available), 1);
          }
        }

        &__hold {
          &::before {
            background-color: rgba(97, 84, 171, 1);
            background-color: rgba(color(hold), 1);
          }
        }

        &__townhomes {
          display: none;
        }

        &__builders {
          &:before {
            background-color: rgba(103, 156, 206, 1);
            background-color: rgba(color(builders), 1);
          }
          &::after {
          }
        }

        &__sold {
          &::after {
            // background-color: rgba(159, 41, 94, 1);
            background-color: rgba(color(sold), 1);
          }
        }

        &__townhomes:before {
          background: #e4ce4c;
        }
      }

      &--container {
        margin-bottom: 0;

        > img {
          display: block;
        }
      }

      a:not(.builder):not(.hold):not(.hold) polygon {
        fill: rgba(color(orange), 0.8) !important;
      }
    }

    &--downloads {
      display: none;
    }

    &--lot {
      padding-right: 0;

      h3 {
        &.available {
          padding-bottom: 5px;
          border-bottom: 1px color(green-400) solid;
          letter-spacing: 1.14px;
          font-family: "brandon-grotesque", sans-serif;
          font-weight: bold;
          color: color(green-dark);
          font-size: 16px;
        }
      }
    }
  }
}
.results {
  .package {
    &--title {
      h3 {
        span {
          color: color(green-dark);
          font-size: 22px;
        }
        span.available {
          color: color(green-dark);
          font-weight: 700;
          font-family: "brandon-grotesque", sans-serif;
        }
      }
    }

    &--details {
      &__item {
        color: color(green-dark);
      }
    }
  }
}
