@import '~traffic-property-app/assets/scss/settings';

$padding: 16px;

body {
    &.ta-overflow {
        overflow: hidden;
    }
}

#traffic-app {
    padding: 20px 0;
}

.ta-container {
    margin: 0 auto;
    max-width: calc(1616px + #{$padding * 2});
    padding: 0 $padding;
}

.ta-row {
    @include grid-container;
    @include grid-collapse;
    // @include grid-visual;
}