@import "~traffic-property-app/assets/scss/settings";
@import "~react-id-swiper/lib/styles/scss/swiper.scss";

.packagedetail {
  &--content {
    @include grid-column(12);

    @include grid-media($large) {
      @include layout-m-right;
    }
  }

  &--gallery {
    position: relative;
    margin: 0 0 40px 0;
    // height: 190px;

    // @include grid-media($medium) {
    // height: 350px;
    // }

    // @include grid-media($large) {
    // height: 250px;
    // }

    // @include grid-media($xlarge) {
    // height: 500px;
    // }

    &:after {
      content: " ";
      display: block;
      clear: both;
    }

    &__left {
      position: relative;
      width: 100%;
      height: 100%;
      margin-right: 8px;
      background-size: cover;
      background-position: right bottom;
      background-repeat: no-repeat;
      transition: opacity 0.3s ease;
      box-sizing: border-box;

      @include grid-media($medium) {
        float: left;
        padding-right: 158px;
      }

      &:hover {
        opacity: 0.85;
        cursor: zoom-in;
      }

      img {
        max-width: 100%;
        display: block;

        @include grid-media($xxlarge) {
          padding-right: 60px;
        }
      }
    }

    &__right {
      display: none;
      float: left;
      width: 150px;
      height: 100%;

      @include grid-media($medium) {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 150px;
      }

      @include grid-media($xxlarge) {
        width: 210px;
      }
    }

    &__bottom {
      position: relative;
      margin-top: 30px;
      text-transform: uppercase;
      font-size: 12px;
      font-family: $themeFontMedium;
      letter-spacing: 0.8px;

      @include grid-media($medium) {
        display: none;
      }
    }

    &__viewfloorplan {
      float: left;
      cursor: pointer;

      .ta-icon {
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        margin-right: 10px;
        width: 24px;
        height: 24px;
        background-size: 24px auto;
        background-repeat: none;
        background-position: center center;
      }
    }

    &__count {
      float: right;
    }

    &__builder {
      position: absolute;
      right: 0;
      top: 0;
      max-height: 40px;
      width: auto;
      max-width: 100px;

      @include grid-media($medium) {
        right: 158px;
        max-height: 75px;
        max-width: 130px;
      }

      @include grid-media($large) {
        max-height: 100px;
        max-width: 175px;
      }
    }

    &__flag {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 10px;
      text-transform: uppercase;
      color: color(black);
      background: color(blue);
      padding: 8px 10px;
      font-family: $themeFontBold;
      letter-spacing: 2px;
    }

    &__thumb {
      position: relative;
      height: calc(33.33% - 5px);
      margin-top: 8px;
      background-color: color(grey4);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 0.85;
      }

      &:first-child {
        margin-top: 0;
        height: calc(33.33% -6px);
        cursor: zoom-in;
      }

      span {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 40px;
        width: 40px;
        color: color(white);
        text-align: center;
        padding-top: 12px;
        background-color: color(black);
        background-size: 25px auto;
        background-repeat: no-repeat;
        background-position: center center;
        font-size: 15px;
        font-family: $themeFontMedium;

        &.marker {
          background-size: 20px auto;
        }

        &.loupe {
          background-size: 40px auto;
        }
      }
    }
  }

  &--map {
    margin: 0 0 40px;

    h4 {
      color: color(orange);
      font-size: $font-size-xl;
      line-height: $font-size-xl;
      font-family: $appFontBold;
      padding-top: 40px;
      margin: 0 0 20px;
    }
  }

  &--downloads {
    padding-top: 30px;
    padding-bottom: 100px;

    h4 {
      text-transform: uppercase;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 2px;
      font-family: $themeFontMedium;
    }

    .ta-btnbtn {
      display: inline-block;
      max-width: none;
      width: auto;
      min-width: 100px;
      margin-left: 8px;
      margin-bottom: 8px;
      text-align: left;
      font-family: $themeFontMedium;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &:nth-child(3) {
        margin-left: 0;
        display: block;
        max-width: 180px;
      }

      @include grid-media($small) {
        &:nth-child(3) {
          margin-left: 8px;
          display: inline-block;
        }
      }

      @include grid-media($medium) {
        background-image: url(~traffic-property-app/assets/icons/others/download.svg);
        background-repeat: no-repeat;
        background-position: 94% center;
        background-size: 16px auto;
        padding-right: 35px;

        &:hover {
          background-image: url(~traffic-property-app/assets/icons/others/download-inverted.svg);
        }

        &:nth-child(3) {
        }
      }
    }
  }

  .package--inclusions {
    @include grid-media($large) {
      display: none;
    }
  }
}
