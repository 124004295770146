@font-face {
  font-family: "Bebas Neue Bold";
  src: local("Bebas Neue Bold"), local("BebasNeuePro-Bold"),
    url(~traffic-property-app/assets/fonts/BebasNeuePro-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: "Gotham Light";
  src: local("Gotham Light"), local("Gotham-Light"),
    url(~traffic-property-app/assets/fonts/Gotham-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: "Gotham Book";
  src: local("Gotham Book"), local("Gotham-Book"),
    url(~traffic-property-app/assets/fonts/Gotham-Book.otf);
  font-weight: 400;
}

@font-face {
  font-family: "Gotham Medium";
  src: local("Gotham Medium"), local("Gotham-Medium"),
    url(~traffic-property-app/assets/fonts/Gotham-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "Gotham Bold";
  src: local("Gotham Bold"), local("Gotham-Bold"),
    url(~traffic-property-app/assets/fonts/Gotham-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: "Gotham Narrow Light";
  src: local("Gotham Narrow Light"), local("GothamNarrow-Light"),
    url(~traffic-property-app/assets/fonts/GothamNarrow-Light.otf);
  font-weight: 300;
}

@font-face {
  font-family: "Gotham Narrow Book";
  src: local("Gotham Narrow Book"), local("GothamNarrow-Book"),
    url(~traffic-property-app/assets/fonts/GothamNarrow-Book.otf);
  font-weight: 400;
}

@font-face {
  font-family: "Gotham Narrow Medium";
  src: local("Gotham Narrow Medium"), local("GothamNarrow-Medium"),
    url(~traffic-property-app/assets/fonts/GothamNarrow-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: "Gotham Narrow Bold";
  src: local("Gotham Narrow Bold"), local("GothamNarrow-Bold"),
    url(~traffic-property-app/assets/fonts/GothamNarrow-Bold.otf);
  font-weight: 700;
}
