@import "~traffic-property-app/assets/scss/settings";

.container {
  overflow: hidden;
}

.wrapper {
  height: 100%;
}

.map {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 1280px;
}

.markers {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.marker {
  position: absolute;
  width: 28px;
  height: 28px;
  border: none;
  background-color: $map-marker-bg;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  transform-origin: 50% 50%;
  font-size: 14px;
  font-family: $themeFontBook;
  font-weight: bold;

  // @include grid-media($touchtable) {
  //   width: 48px;
  //   height: 48px;
  //   font-size: 22px;
  // }

  &:before {
    content: " ";
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 3px transparent solid;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // @include grid-media($touchtable) {
    //   width: 48px;
    //   height: 48px;
    //   font-size: 22px;
    // }
  }

  &.active {
    &:before {
      animation-name: pulse;
      animation-duration: 1.8s;
      animation-iteration-count: infinite;

      @keyframes pulse {
        from {
          transform: translate(-50%, -50%) scale(1);
        }

        50% {
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }

        100% {
          transform: translate(-50%, -50%) scale(3);
          opacity: 0;
        }
      }
    }
  }
}

.amenitieszoom {
  position: absolute;
  bottom: 40px;
  right: 680px;
  width: 220px;
  height: auto;
  display: none;

  // @include grid-media($touchtable) {
  //   right: auto;
  //   left: 220px;
  //   bottom: 300px;
  //   width: 430px;
  // }
}

.north {
  position: absolute;
  top: 50px;
  right: 680px;
  width: 40px;

  // @include grid-media($touchtable) {
  //   display: none;
  // }
}