@import "~traffic-property-app/assets/scss/settings";

.enquiryform {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 40px 16px 130px;
  background: color(white);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include grid-media($large) {
    padding: 130px 16px 130px;
  }

  iframe {
    min-height: 550px;
  }

  &--content {
    position: relative;
    @include grid-column(12);

    @include grid-media($large) {
      @include grid-column(10);
      @include grid-push(1);
    }
  }

  .close {
    position: absolute;
    display: block;
    top: -10px;
    right: 0;
    width: 20px;
    height: 20px;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      transform: rotate(180deg);
    }

    @include grid-media($large) {
      width: 24px;
      height: 24px;
      background-size: 20px;
    }
  }

  h3 {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 1.29px;
    text-transform: uppercase;
    font-family: $themeFontBold;
  }

  h2 {
    font-size: 30px;
    line-height: 38px;
    text-transform: uppercase;
    font-family: $appFontBold;
    color: color(orange);

    @include grid-media($large) {
      font-size: 52px;
      line-height: 60px;
    }

    span {
      display: block;
      color: color(black);
      margin-top: 10px;
    }
  }

  form {
    display: block;
    padding: 0;
    margin: 0;
    max-width: 710px;
  }

  &--field {
    @include grid-column(12);
    margin-bottom: 25px;

    @include grid-media($large) {
      @include grid-column(6);
    }

    &.checkbox,
    &.button {
      @include grid-column(12);
    }

    label {
      display: block;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-family: $themeFontBold;
      margin-bottom: 10px;
    }

    input {
      display: block;
      width: 100%;
      box-shadow: none;
      background: none;
      border: 1px color(black) solid;
      padding: 8px;
      height: 40px;
      font-family: $themeFontBook;

      &[type="checkbox"] {
        display: none;

        + label {
          font-size: 10px;
          letter-spacing: 0;
          font-family: $themeFontBook;
          text-transform: none;

          a {
            color: color(black);
          }

          &:before {
            content: " ";
            display: inline-block;
            vertical-align: middle;
            width: 15px;
            height: 15px;
            margin: -2px 10px 0 0;
            border: 1px color(black) solid;
          }
        }
      }
    }

    button {
      width: 100%;
      max-width: 100%;
    }
  }
}
