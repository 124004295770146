@import "~assets/scss/settings";

.content {
  position: fixed;
  // top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  left: 80px;
  width: 1200px;
  // height: 270px;
  // overflow: hidden;
}

.header {
  position: absolute;
  width: 100%;
  bottom: 270px;
  top: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 1200px;
  height: 270px;
  @include pt(3);
  background: color(grey4);
}

.left_bar {
  position: absolute;
  left: -80px;
  top: 0;
  bottom: 0;
  // background: color(white);
  width: 80px;
  height: 150px;
}
