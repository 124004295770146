.packagesList {
  box-shadow: none;
  &__wrapper {
    width: 217px;
    min-width: 217px;
  }

  .package--image {
    width: 100% !important;
  }

  .package--content {
    .package--title {
      h3 {
        font-size: 14px;
        font-family: 'Lato', sans-serif;

        span {
          &:first-of-type {
            text-transform: uppercase;
            color: color(green-dark);
            font-weight: 700;
          }

          &:last-of-type {
            color: color(green-400);
            font-weight: 500;
          }
        }
      }
    }
    .package--details {
      color: color(green-dark);
    }

    .package--specs {
      background-color: #fff;
      padding-left: 0;

      .package--price {
        color: color(green-dark);

        .package--price__from {
          text-transform: none;
          font-weight: 700;
        }

        .package--price__number {
          font-weight: 500;
        }
      }
    }
  }
}
