// - - - - - - - - - - - - - - - - -
// Fonts
// - - - - - - - - - - - - - - - - -
$appFontBold: "brandon-grotesque", sans-serif;
$themeFontLight: "brandon-grotesque", sans-serif;
$themeFontBook: "brandon-grotesque", sans-serif;
$themeFontMedium: "brandon-grotesque", sans-serif;
$themeFontBold: "brandon-grotesque", sans-serif;
$themeHeadings: "brandon-grotesque", sans-serif;

$font-size-md: 24px;
$font-size-lg: 19px;
$font-size-xl: 32px;
$font-size-xxl: 32px;

// - - - - - - - - - - - - - - - - -
// Grid
// - - - - - - - - - - - - - - - - -

$neat-grid: (
  columns: 12,
  gutter: 16px
);

$small: (
  media: 576px
);
$medium: (
  media: 768px
);
$large: (
  media: 992px
);
$xlarge: (
  media: 1200px
);
$xxlarge: (
  media: 1680px
);
$xxxlarge: (
  media: 1920px
);
$touchtable: (
  media: 3840px
);

// array of theme colors
$colors: (
  orange: #ffaf11,
  blue: #d2e9e7,
  blue-dark: #c3e6e3,
  blue-mid: #d9eff7,
  blue-light: #effffe,
  purple: #582c83,
  pink: #f3a7b5,
  yellow: #e6cd21,
  green: #004e44,
  green-dark: #004e44,
  green-200: rgba(#bad982, 0.3),
  green-400: #00af6b,
  red: #ba0c2f,
  black: #000000,
  grey1: #f3f3f3,
  grey2: #999999,
  grey3: #cbc4bc,
  grey4: #f7f7f7,
  grey5: #231d16,
  white: #ffffff,
  white-off: #f1f8f7,
  available: #a671af,
  hold: #6154ab,
  builders: #679cce,
  sold: #9f295e,
  buttonPrimary: #c1de8d
);

$amenities-sidebar-bg: #fff;
$amenities-button-icon-bg: lighten(color(orange), 30);
$amenities-button-icon-color: color(white);
$amenities-button-icon-active-bg: color(black);
$amenities-button-icon-active-color: color(white);
$map-marker-bg: color(black);
$map-marker-color: color(white);
$map-marker-active-color: color(orange);

// - - - - - - - - - - - - - - - - -
// px to em calc
// - - - - - - - - - - - - - - - - -

$browser-context: 16; // Default
