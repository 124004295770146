@import "~traffic-property-app/assets/scss/settings";

.checklist {
  .checkbox {
    display: inline-block;
    margin: 0 8px 8px 0;

    .status-options &,
    .other-options & {
      // margin-bottom: 16px;
      label {
        padding: 12px 10px 0;
        padding-left: 30px;
        height: 38px;
        font-size: 14px;
        font-family: $themeFontBook;
        background-size: 24px;
      }
    }

    @include grid-media($xlarge) {
      margin: 0 16px 16px 0;

      .ta-has-results & {
        margin: 0 8px 8px 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.checkbox__icon {
      .status-options &,
      .other-options & {
        label {
          padding: 12px 10px 0 40px;

          @include grid-media($xxlarge) {
            .ta-has-results & {
              padding: 12px 10px 0 30px;
            }
          }
        }
      }
    }

    &.hasImage {
      .logo {
        display: block;
        height: 40px;
        margin-bottom: 20px;

        @include grid-media($small) {
          height: 80px;

          .ta-has-results & {
            height: 58px;
            margin-bottom: 10px;
          }
        }
      }

      label {
        padding-top: 0 !important;
        padding: 0;
        height: auto;
        border: none;
        text-align: center;
        font-size: 10px;
        font-family: $themeFontMedium;

        @include grid-media($small) {
          font-size: 13px;

          .ta-has-results & {
            font-size: 10px;
          }
        }

        &:hover {
          border: none;
        }

        &.no-icon {
          padding-left: 0;
          padding-right: 0;
        }
      }

      input:checked + label {
        opacity: 1;
        background-color: transparent;
      }
    }

    input {
      display: none;

      &:checked + label {
        opacity: 1;
        background-color: color(lightblue);
      }
    }

    label,
    span,
    a {
      display: inline-block;
      height: 38px;
      padding: 12px 10px 0;
      opacity: 0.5;
      color: color(black);
      border: 1px color(black) solid;
      background-repeat: no-repeat;
      background-position: 6px center;
      background-size: auto 26px;
      text-transform: uppercase;
      font-family: $themeFontMedium;
      font-size: 13px;
      letter-spacing: 1.14px;
      line-height: 15px;
      cursor: pointer;
      transition: all 0.3s ease;
      text-decoration: none;

      &:hover {
        border: 1px color(buttonPrimary) solid;
      }

      @include grid-media($xxlarge) {
        height: 48px;
        font-size: 16px;
        padding: 17px 25px 0;
        background-size: auto 31px;
        background-position: 10px center;

        .ta-has-results & {
          height: 38px;
          padding: 12px 10px 0;
          font-size: 13px;
          background-position: 6px center;
          background-size: auto 26px;
        }

        .other-options & {
          .ta-has-results & {
            height: 38px;
            padding: 13px 8px 0 52px;
            font-size: 13px;
            background-position: 6px center;
            background-size: auto 16px;
          }
        }

        &.no-icon {
          @include grid-media($xxlarge) {
            padding-left: 25px;
          }
        }
      }
    }

    &.select-all {
      label {
        text-transform: capitalize;

        .other-options &,
        .ta-has-results & {
          padding-left: 10px !important;
        }

        .other-options & {
          padding-top: 12px;
        }
      }

      input:checked + label {
        color: color(white);
        border-color: color(black);
        background-color: color(black);
      }
    }

    &.checkbox__icon {
      label,
      span,
      a {
        padding-left: 52px;
        @include grid-media($xxlarge) {
          padding-left: 80px;

          .ta-has-results & {
            padding-left: 52px;
          }
        }
      }
    }
  }

  &__secondary .checkbox {
    label,
    span,
    a {
      opacity: 1;
      color: color(black);

      &:hover {
        border-color: color(orange);
      }
    }

    &:not(.checkbox__icon) {
      input:checked + label {
        color: color(white);
        border-color: color(orange);
        background-color: color(orange);
      }
    }
  }
}
