@import "~assets/scss/settings";

.main {
  position: relative;
  // margin-right: 160px;
  height: 100vh;
  overflow: hidden;
}

.navbar {
  background: color(blue) !important;
}
