.logo {
  display: inline-block;

  svg {
    height: auto;
    width: 100%;
    padding: 10px;
  }

  &.sm {
    width: 120px;
  }

  &.lg {
    width: 249px;
  }

  &.xl {
    width: 385px;
  }
}
