@import "~traffic-property-app/assets/scss/settings";

.socialsharing {
  @include grid-column(12);
  padding: 8px;
  padding-right: 0;
  text-align: right;

  @include grid-media($large) {
    margin-top: 0;
  }

  .packagedetail--content & {
    display: none;
  }

  a {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: cover;
    margin-left: 20px;
    transition: all .15s ease;

    &.facebook {
      margin-left: 10px;
    }

    &:hover {
      opacity: 0.65;
    }
  }
}