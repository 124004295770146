@import "~traffic-property-app/assets/scss/settings";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  visibility: hidden;
  background: color(black);
  opacity: 0;
  cursor: pointer;
  transition: visibility 0s, all 0.3s ease;

  &.show {
    visibility: visible;
    opacity: 0.4;
  }
}

.container {
  position: fixed;
  // height: 66px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: color(white);
  z-index: 10000;

  // @include grid-column(12);
  // padding: 8px;
  // padding-right: 0;
  // text-align: right;
  //
  // @include grid-media($large) {
  //   margin-top: 0;
  // }

  &.show {
    .closeButton :global(.ta-icon) {
      transform: rotate(0) !important;
    }
  }

  .content {
    transition: all 0.5s ease;
    max-height: 0;

    .wrapper {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      width: 100%;
      min-height: calc(100vh - 124px);
      max-height: calc(100vh - 124px);

      @include grid-media($medium) {
        min-height: unset;
      }

      &.recentlyViewed {
        @include grid-media($medium) {
          max-height: 312px;
        }
      }
    }

    .titleContainer {
      overflow: auto;
    }

    .title {
      overflow: auto;
      margin-top: 40px;
      @include grid-column(12);
      position: relative;
      display: block;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 14px;
      font-family: $themeFontMedium;
      font-weight: normal;

      @include grid-media($xlarge) {
        font-size: $font-size-lg;
        line-height: $font-size-lg;
        margin-bottom: 25px;
        letter-spacing: 1.29px;
        font-family: $appFontBold;
      }

      &.compare {
        margin-bottom: 0;
      }
    }
  }

  &.show {
    .content {
      min-height: calc(100vh - 64px);
      max-height: calc(100vh - 64px);
      // height: 430px;
      @include grid-media($medium) {
        height: auto;
        min-height: unset;
        max-height: 100vh;
      }
    }
  }

  .menuWrapper {
    background-color: color(orange);

    ul {
      padding: 20px 0;
      width: 100%;
      text-align: center;
      font-size: 10px;
      font-family: $themeFontMedium;
      position: relative;
      @include grid-media($medium) {
        font-size: 14px;
      }

      .title {
        color: color(white);
      }

      // &,
      // li {
      //   margin: 0;
      // }

      li {
        display: inline-block;
        padding: 0 4px;
        vertical-align: middle;
        position: relative;

        @include grid-media($medium) {
          padding: 0 10px;
        }
        // height: 100%;
        //
        &:nth-child(n + 2) {
          padding: 0 7px;

          @include grid-media($medium) {
            padding: 0 22px;
          }

          &:not(:last-child):not(:nth-last-child(2)):after {
            content: "  ";
            border-right: 2px solid rgba(color(white), 0.15);
            height: 220%;
            color: color(white);
            font-weight: lighter;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }

          &:last-child {
            padding-left: 2px;
            padding-right: 0;
          }
        }

        &:nth-child(2) {
          padding-left: 10px;
        }

        button {
          cursor: pointer;
          background: transparent;
          border: 0;
          text-transform: uppercase;
          padding: 0;
          opacity: 1;
          transition: color 0.3s ease;

          &:hover {
            color: color(white);
          }

          &:after {
            content: " ";
            opacity: 0;
            color: color(white);
            transition: opacity 0.3s ease;
          }
        }

        &.active {
          button {
            &:hover {
              color: initial;
            }

            &:after {
              content: " • ";
              position: absolute;
              opacity: 1;
              top: 80%;
              left: 0;
              right: 0;
            }
          }
        }
      }
    }

    .close {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      @include grid-media($medium) {
        right: 20px;
      }

      .closeButton {
        background-color: transparent;
        border: 0;

        &:hover {
          :global(.ta-icon) {
            filter: invert(1);
          }
        }

        :global(.ta-icon) {
          transform: rotate(180deg);
          height: 25px;
          width: 20px;
          margin: 0;
          background-repeat: no-repeat;
          background-size: auto 7px;
          background-position: center;
          transition: 0.3s ease all;
          display: inline-block;
          vertical-align: middle;

          @include grid-media($medium) {
            height: 35px;
            width: 30px;
            background-size: auto 10px;
          }
        }
      }
    }
  }
}
