@import "~traffic-property-app/assets/scss/settings";

.floorplates {
  @include grid-column(12);

  &--nav {
    @include grid-column(12);
    margin-bottom: 40px;

    @include grid-media($large) {
      @include grid-column(4);
      margin-bottom: 0;
    }

    @include grid-media($xlarge) {
      @include grid-column(3);
    }

  }

  &--map {
    @include grid-column(12);

    @include grid-media($large) {
      @include grid-column(8);
    }

    @include grid-media($xlarge) {
      @include grid-column(8);
      @include grid-push(1);
    }


    text {
      transform: translateX(-30px);
    }

    a {
      polygon {
        // fill: rgba(color(grey3), .6);
      }

      &:hover {
        polygon {
          // fill: rgba(color(grey3), .85);
        }
      }
    }
  }

  h3 {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px color(orange) solid;
    font-size: $font-size-xl;
    line-height: $font-size-xl;
    font-family: $appFontBold;
    text-transform: uppercase;
    color: color(orange);
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 12px 0;
      border-bottom: 1px color(grey3) solid;
      cursor: pointer;

      &.active {
        font-family: $themeFontMedium;
      }
    }
  }

}