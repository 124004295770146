@import "~traffic-property-app/assets/scss/settings";

img.levelimg {
  position: relative;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;

  &.parent {
    z-index: 1;
    position: relative;
    top: auto;
  }

  &.active {
    z-index: 1;
  }
}

.legend {
  position: absolute;
  z-index: 2;
  right: 10px;
  bottom: 12px;
  background: color(white);
  padding: 10px;
  border-radius: 2px;

  > span {
    display: inline-block;
    margin: 0 0 0 13px;
    font-size: 11px;
    font-family: $themeFontBook;

    .legend__color,
    &:not(.legend__color-wrapper):before {
      content: " ";
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;
      margin: 0 8px 0 0;
      border-radius: 50%;

      @include grid-media($large) {
        width: 24px;
        height: 24px;
      }
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__available {
    &:before {
      background: color(available);
    }
  }

  &__hold {
    &:before {
      background: color(hold);
    }
  }

  &__builders {
    &:before {
      background: color(builders);
    }
  }

  &__sold {
    &:before {
      background: color(sold);
      // background: rgba(159,41,94,1);
    }
  }
}

.ta-neighbourhood--stage-1 {
  &[class*="acacia"] {
    svg {
      .available {
        fill: rgba(166, 113, 175, 0);
      }
    }
  }
}

.ta-neighbourhood--stage-1:not([class*="acacia"]) {
  svg {
    .available {
      // fill: rgba(255, 175, 17, 0.5) !important;
      fill: rgba(192, 169, 209,.75) !important;
    }
  }

  .legend {
    .legend__sold {
      // display: none;
    }
  }
}
