.videos {
  width: calc(100% - 160px);
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 40px;
  align-content: start;
  padding: 40px;

 
}
