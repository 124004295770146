@import "~traffic-property-app/assets/scss/settings";

.lfs {
  &--lot {
    // @include grid-column(12);

    .package--subtitle {
      padding-top: 0;
      height: 40px;
    }

    @include grid-media($large) {
      @include layout-s-left;
    }

    h3 {
      &.available {
        margin: 0;
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 18px;
        color: color(black);
        font-family: $themeFontMedium;
        text-transform: uppercase;
        @include grid-column(12);
      }
    }

    h4 {
      margin: 0 0 15px 0;
      color: color(orange);
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.75px;
      font-family: $themeFontMedium;
      text-transform: uppercase;
    }
  }
}
