@import "~traffic-property-app/assets/scss/settings";

.container {
  float: left;
  display: block;

  .from {
    display: block;
    margin: 0 0 3px 0;
    font-size: 10px;
    letter-spacing: 0.08px;
    line-height: 10px;
    font-family: $themeFontMedium;
    text-transform: uppercase;
  }

  .number {
    display: block;
    font-size: 20px;
    line-height: 20px;
    font-family: $appFontBold;

    @include grid-media($small) {
      font-size: 28px;
      line-height: 28px;
    }

    @include grid-media($medium) {
      font-size: 32px;
      line-height: 32px;
    }
  }
}
