@import "~traffic-property-app/assets/scss/settings";

.container {
  position: relative;
  display: block;
  font-family: $appFontBold;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 15px;

  .text,
  .secondaryText {
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;
    font-size: $font-size-lg - 1px;
    line-height: $font-size-lg - 1px;
    @include grid-media($medium) {
      font-size: $font-size-lg;
      line-height: $font-size-lg;
    }
    // @include grid-media($xlarge) {
    //   font-size: 32px;
    //   line-height: 32px;
    // }
  }

  .secondaryText:before {
    content: " ";
    display: inline-block;
    overflow: hidden;
    height: 24px;
    width: 1px;
    margin: 0 6px 0;
    margin-top: -3px;
    background: color(grey3);
    vertical-align: top;

    @include grid-media($medium) {
      margin: 0 10px 0;
      margin-top: 3px;
      height: 32px;
      margin-top: -4px;
    }
  }

  &.small {
    .text,
    .secondaryText {
      font-size: $font-size-lg - 9px;
      line-height: $font-size-lg - 9px;
      @include grid-media($medium) {
        font-size: $font-size-lg - 3px;
        line-height: $font-size-lg - 3px;
      }
    }

    .secondaryText:before {
      margin: 0 7px 0;
      margin-top: 3px;
      height: 27px;
      margin-top: 0;
    }
  }

  .header {
    color: color(orange);
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 0.81px;
    font-family: $themeFontBold;
    line-height: 9px;
    font-size: 9px;
    margin: 0;

    @include grid-media($medium) {
      line-height: 10px;
      font-size: 10px;
    }
  }
}
