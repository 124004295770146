@import "~traffic-property-app/assets/scss/settings";

.apartments {
  .ad {
    &--wrapper,
    &--item {
      @include grid-column(12);
    }

    &--item {
      margin-bottom: 80px;
    }
  }

  &--amenities {
    float: left;
    display: block;
    padding-top: 2px;
    color: color(orange);
    font-size: 14px;
    font-family: $themeFontMedium;
    line-height: 14px;

    @include grid-media($small) {
      font-size: 16px;
      line-height: 16px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 4px;

      @include grid-media($small) {
        margin-left: 7px;
      }
    }

    .ta-icon {
      height: 40px;
      width: 30px;
      margin: 0;
      background-repeat: no-repeat;
      background-size: auto 14px;
      background-position: center 12px;

      @include grid-media($small) {
        background-size: auto 16px;
        background-position: center 10px;
      }
    }
  }

  &--filter {
    @include grid-column(12);
    // @include grid-push(1);
    background: color(grey4);
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 80px;

    @include grid-media($large) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    h3 {
      font-size: $font-size-lg;
      line-height: $font-size-lg;
      margin: 0 0 30px 0;
      text-transform: uppercase;
      font-family: $appFontBold;

      span {
        display: inline-block;
        vertical-align: middle;
        margin-top: -3px;
        font-size: 14px;
        font-family: $themeFontBook;
        text-transform: none;
        font-weight: normal;
      }
    }
  }

  &--filtertype,
  &--filterplan {
    @include grid-column(12);

    @include grid-media($large) {
      @include grid-column(10);
      @include grid-push(1);
    }
  }

  &--filtertype {
    margin-bottom: 50px;

    @include grid-media($large) {
      margin-bottom: 100px;
    }

    .checkbox {
      label {
        margin-bottom: 16px;
      }
    }
  }

  &--filterplan {
    > button {
      max-width: 280px;
    }

    &--items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    &--item {
      flex-basis: 50%;
      margin: 0 0 30px 0;

      @include grid-media($large) {
        flex-basis: 33.33%;
        margin: 0 0 60px 0;
      }

      button {
        box-shadow: none;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: default;
        }

        &:hover {
          &:not(:disabled) {
            img {
              @include grid-media($large) {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      img {
        display: block;
        margin-bottom: 20px;
        transition: all 0.2s ease;
        max-width: 300px;
        max-height: 260px;

        @include grid-media($large) {
          margin-bottom: 40px;
        }
      }

      &__name {
        text-align: left;
        text-transform: uppercase;
        font-size: 14px;
        font-family: $themeFontMedium;
      }
    }
  }

  &--filterfloor {
    @include grid-column(12);
    margin-bottom: 50px;

    @include grid-media($large) {
      @include grid-column(8);
      @include grid-push(1);
      margin-bottom: 0;
    }

    button {
      position: relative;
      display: inline-block;
      padding: 0 8px 5px;
      margin: 0 0 0 20px;
      background: none;
      border: none;
      box-shadow: none;
      outline: 0;
      color: color(black);
      font-size: 14px;
      font-family: $themeFontBold;
      cursor: pointer;

      &:before {
        content: " ";
        display: block;
        margin: 0 0 15px 0;
        width: 10px;
        height: 10px;
        background: color(orange);
        border-radius: 50%;
      }

      &:after {
        content: " ";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60%;
        z-index: 0;
        background: transparent;
        transition: all 0.3s ease;
      }

      &:disabled {
        cursor: default;
        &:before {
          background: color(grey3);
        }
      }

      &.active {
        &:after {
          background: color(orange);
        }
      }

      &:first-child {
        margin-left: 0;
      }

      span {
        position: relative;
        z-index: 1;
      }
    }
  }

  &--selectedplan {
    @include grid-column(12);

    @include grid-media($large) {
      @include grid-column(3);
    }

    img {
      display: block;
      margin-bottom: 20px;
      width: 100%;
      max-width: 250px;
    }

    span {
      font-size: 14px;
      font-family: $themeFontMedium;
    }

    &--label {
      display: block;
      margin-bottom: 5px;
    }

    &--amenities {
      display: inline-block;

      span {
        display: inline-block;
        color: color(orange);
        margin-right: 10px;

        &.ta-icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-left: 5px;
          margin-top: 2px;
          background-repeat: no-repeat;
          background-position: center bottom;
          background-size: 20px;
        }
      }
    }
  }

  &--results {
    @include grid-column(12);
    margin-bottom: 60px;
  }
}
