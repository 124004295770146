@import "~traffic-property-app/assets/scss/settings";

.container {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  @include grid-media($large) {
    display: initial;
    flex-wrap: none;
    // height: 800px;
  }

  .title {
    text-transform: uppercase;
    font-size: $font-size-xxl;
    line-height: $font-size-xxl;
    font-family: $appFontBold;

    @include grid-media($large) {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }

  .description {
    font-size: 16px;
    font-weight: normal;
    font-family: $themeFontBook;
    @include grid-media($large) {
      margin-bottom: 30px;
    }
  }

  .leftContent,
  .rightContent {
    flex: 100% 0 0;
    margin-left: 0 !important;

    @include grid-media($large) {
      margin-left: initial;
      height: 100%;
    }
  }

  .leftContent {
    @include grid-column(12);
    order: 2;

    @include grid-media($large) {
      @include grid-column(4);
      padding-right: 20px;
    }

    @include grid-media($xxlarge) {
      padding-right: 40px;
    }

    .content {
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
      @include grid-media($large) {
        height: 100%;
      }

      ul {
        background-color: $amenities-sidebar-bg;
        height: 100%;
        max-height: 370px;
        overflow: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        @include grid-media($large) {
          margin-top: 10px;
        }

        li {
          padding-top: 10px;
          padding-bottom: 10px;

          &:global(.active) {
            button .icon {
              background-color: $amenities-button-icon-active-bg;
              color: $amenities-button-icon-active-color;
              font-weight: normal;
            }
          }

          button {
            border: 0;
            background: none;
            font-size: 14px;
            width: 100%;
            text-align: left;
            margin: 0;

            span {
              vertical-align: middle;
            }

            .icon {
              border-radius: 50%;
              background-color: $amenities-button-icon-bg;
              color: $amenities-button-icon-color;
              width: 25px;
              height: 25px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              font-family: $themeFontBook;
            }

            .name {
              margin-left: 10px;
              font-size: 14px;
              font-family: $themeFontBook;
            }
          }
        }
      }

      .categories {
        z-index: 1012;
        position: absolute;
        left: 20px;
        right: 0;
        top: -60px;
        overflow: hidden;
        // background: $amenities-sidebar-bg;

        @include grid-media($large) {
          position: relative;
          top: initial;
          left: initial;
          right: initial;
          padding: 0 24px;
        }

        :global {
          .swiper-container {
            padding: 12px 0;
            overflow: unset;
            background: $amenities-sidebar-bg;
            @include grid-media($large) {
              background: transparent;
            }

            .swiper-button-prev,
            .swiper-button-next {
              height: 16px;
              margin-top: -8px;
              display: none;
              width: 16px;

              &:after {
                content: " ";
                position: absolute;
                background: color(white);
                width: 10px;
                height: 20px;
              }

              @include grid-media($large) {
                display: block;
              }
            }

            .swiper-button-prev {
              margin-left: -28px;
              filter: grayscale(1);
              background-position: center left;

              &:after {
                left: -9px;
              }
            }

            .swiper-button-next {
              margin-right: -28px;
              filter: grayscale(1);
              background-position: center right;

              &:after {
                right: -9px;
              }
            }
          }
        }

        @include grid-media($large) {
          margin-top: inherit;
          margin-bottom: inherit;
        }

        button {
          background: none;
          border: 0;
          width: 100%;
          text-transform: uppercase;
          font-size: 14px;
          font-family: $themeFontBook;
        }
      }
    }
  }

  .rightContent {
    @include grid-column(12);
    height: 50vh;
    order: 1;

    @include grid-media($large) {
      min-height: 600px;
      height: 100%;
      order: 3;
      @include grid-column(8);
    }
  }
}

:global(.active) {
  &,
  button {
    font-weight: bold;
  }
}
