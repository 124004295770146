@import "~traffic-property-app/assets/scss/settings";

.promo {
  margin: 0 0 40px 0;

  div {
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .mobile {
    display: block;
  }

  .desktop {
    display: none;
  }

  @include grid-media($medium) {
    .mobile {
      display: none;
    }

    .desktop {
      display: block;
    }
  }
}
