@import "~traffic-property-app/assets/scss/normalize";
@import "~traffic-property-app/assets/scss/settings";
@import "~traffic-property-app/assets/scss/typography";
@import "~traffic-property-app/assets/scss/layout";

#traffic-app {
  width: 100%;
  height: auto;
  background: color(white);
  font-size: 16px;
  line-height: 1.2;
  font-family: $themeFontBook;
}

@import "~theme/style";
