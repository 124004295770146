@import "~assets/scss/settings";

.packagesList {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.package {
  @include pl(4);
  margin-left: 0;
  width:205px;

  :global(.package) {
    width: 100%;
  }
}