// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #333333 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #bad982 !default;
$teal: #20c997 !default;
$cyan: #d1e9e8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800
  ),
  $colors
);

$primary: $green !default;
$secondary: $cyan !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
  ),
  $theme-colors
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1680px
) !default;




//Fonts

$font-family-sans-serif: "brandon-grotesque",sans-serif;
$font-family-monospace: $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;
$headings-font-family: $font-family-sans-serif !default;
$subheadings-font-family: "Lato", "Helvetica Neue", helvetica, arial, sans-serif !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-md: 24px;
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;


// Buttons

$input-btn-padding-y: 0.688rem !default;
$input-btn-padding-x: 0.9rem !default;
$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-lg: 1.6rem !default;
$input-btn-padding-x-lg: 8rem !default;
$input-btn-font-size-lg: $font-size-lg * 1.5 !default;

$input-btn-border-width: 1px !default;

$btn-padding-y: $input-btn-padding-y + 0.75rem !default;
$btn-padding-x: $input-btn-padding-x + 0.7 !default;

$btn-border-radius: 0 !default;
$btn-border-radius-lg: $btn-border-radius !default;
$btn-border-radius-sm: $btn-border-radius !default;
//Inputs 

$input-border-color: $black !default;

$input-border-radius: 0 !default;
$input-border-radius-lg: $input-border-radius !default;
$input-border-radius-sm: $input-border-radius !default;

$input-focus-border-color: $black !default;

$form-group-margin-bottom: 2.4rem !default;

$custom-control-indicator-checked-color: $white !default;
$custom-control-indicator-checked-bg: $black !default;

$custom-checkbox-indicator-border-radius: 0 !default;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;
