@import "~assets/scss/settings";

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 80px;
  background: color(green-200);
  padding-left: 0;
  margin-left: 0;
  font-size: 0;
  line-height: 0;
  z-index: 1;
  pointer-events: none;
}
