@import "~assets/scss/settings";

.townhomes {
  height: 100vh;
  z-index: 1;
}

.sidebar {
  @include px(40px);
  font-family: $themeHeadings;
  font-weight: 400;
  line-height: 26px;
}
.title {
  font-family: $themeHeadings;
  font-size: 24px;
  letter-spacing: 0.18px;
  font-weight: 700;
  @include pl(4);
}

.paragraph {
  font-family: $themeFontMedium;
  margin: 0 0 20px 0;
  font-size: 16px;
  @include mt(3);
  @include pl(4);
}

.release {
  display: inline-block;
  margin: 10px 0 0 1.5rem;

  .image {
    width: 248px;
    display: block;
    margin-bottom: 10px;
  }

  .title {
    font-family: "RobotoSlab", sans-serif;
    font-size: 20px;
    color: #000;
    letter-spacing: 0.15px;
    font-weight: 500;
    padding-left: 0;
  }
}

.h1 {
  display: block;
  font-family: "Verlag", sans-serif;
  font-size: 48px;
  letter-spacing: -0.44px;
  color: color(orange);
  text-transform: uppercase;
  margin-top: 15px;
}

.h2 {
  font-family: "RobotoSlab", sans-serif;
  font-size: 20px;
  letter-spacing: 0.15px;
  font-weight: 700;
  margin-bottom: 52px;
}

.h5 {
  display: block;
  font-weight: 400;
  text-transform: uppercase;
  font-family: $themeFontBold;
  letter-spacing: 1.14px;
  font-size: 16px;
  margin-top: 50px;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px #333 solid;
}

.render {
  display: block;
  margin-bottom: 30px;
}

.goback {
  display: block;
  font-family: $themeHeadings;
  font-size: 12px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 20px;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center center;
  transform: rotate(180deg);
  vertical-align: middle;
  margin-right: 10px;
}

:global {
  .legend {
    right: auto !important;
    left: 20px;
  }

  .ta-coord-map--zoom {
    background: color(white);
    padding: 10px;
    padding-top: 15px;
    top: auto !important;
    bottom: 20px;

    &:before {
      content: "zoom";
      position: absolute;
      top: 5px;
      color: color(black);
      font-size: 11px;
    }

    button {
      border: 1px solid color(grey2);
      margin-top: 10px;
    }
  }
}

.mapview,
.listview {
  display: block;

  :global(.ta-icon) {
    display: block;
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.listview {
  margin-top: 30px;
}
.mapview {
  margin-top: 15px;
}


.arrowContainer {
  position: absolute;
  top: 50vh;
  left: 25px;
  z-index: 999;
  .arrowUp {
    background: url("./images/arrowUp.svg");
    background-repeat: no-repeat;
    width: 25px;
    height: 24px;
    margin-bottom: 50px;
  }
  .arrowDown {
    background: url("./images/arrowDown.svg");
    background-repeat: no-repeat;
    width: 25px;
    height: 24px;
  }
}
