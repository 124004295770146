@include grid-media($xxxlarge) {
  .ta-has-results {
    .ta-filter--title {
      font-family: "brandon-grotesque",sans-serif;
      color: color(green-dark);
      font-weight: 700;
      letter-spacing: 0.05em;
    }
    .ta-filter--heading h2 {
      // color: transparent;
      width: 100%;
      border: none;
      margin-top: 70px;
      color: color(green-dark);
      text-transform: none;
      font-size: 16px;

      &::after {
        content: "Results";
        margin-top: -80px;
        display: block;
        font-family: "brandon-grotesque",sans-serif;
        font-size: 48px;
        letter-spacing: -0.44px;
        text-transform: uppercase;
        font-weight: 700;
        color: color(green-dark);
      }
    }

    .results {
      position: absolute;
      top: 0;
      left: 80px;
      width: 1200px;
      min-height: 100vh;
      height: auto;
      margin-left: 0;
      padding: 40px 40px;
      background: color(white-off);

      &--sorting {
        &:not(.no-results) {
          display: none;
        }

        .no-results {
          color: color(black);
        }
      }

      &--count {
        display: block !important;
        width: 40px;
        position: fixed;
        z-index: 20;
        left: 20px;
        top: 50vh;
        font-family: $themeHeadings;
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.18px;
        text-align: center;

        span {
          display: block;
          font-size: 11px;
          font-weight: 300;
        }
      }
    }
  }
}
