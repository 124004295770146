.modal {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
  position: fixed;
  width: calc(100% - 160px);
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  justify-content: center;
  align-items: center;

  .inner {
    background-color: none;

    div {
      width: 1280px !important;
      height: 720px !important;
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;
  }

  .close {
    position: absolute;
    top: 100px;
    right: 100px;
    width: 50px;
    height: 50px;
    z-index: 1;

    &::after,
    &::before {
      content: ' ';
      width: 4px;
      height: 40px;
      position: absolute;
      background-color: #fff;
      left: calc(50% - 2px);
      top: calc(50% - 20px);
    }

    &::after {
      transform: rotate(45deg);
    }

    &::before {
      transform: rotate(-45deg);
    }
  }
}
