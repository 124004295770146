@import "~traffic-property-app/assets/scss/settings";

.ta-filter {
  @include grid-column(12);

  &--recent-searches__wrapper {
    display: none;

    @include grid-media($xlarge) {
      display: block;
    }
  }

  &--similar-properties-package,
  &--recent-searches > a {
    width: 100% !important;
    margin-left: 0 !important;
  }

  &--similar-properties-package {
    margin-top: 0 !important;
    margin-bottom: 40px;

    @include grid-media($xlarge) {
      margin-bottom: 80px;
    }
  }

  &--estates {
    .checkbox label img {
      background: #000;
      padding: 8px 10px;
      width: 120px;

      @include grid-media($small) {
        padding: 16px;
        width: 210px;

        .ta-has-results & {
          @include grid-media($small) {
            padding: 16px;
            width: 176px;
          }
        }
      }
    }
  }

  &--heading {
    display: none;

    .ta-has-results & {
      display: block;

      h2 {
        @include grid-column(12);
        position: relative;
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 1.14px;
        line-height: 18px;
        font-family: $themeFontBold;
        padding-bottom: 30px;
        padding-top: 4px;

        .ta-icon {
          position: absolute;
          right: 0;
          top: 0;
          display: block;
          width: 25px;
          height: 25px;
          background-repeat: no-repeat;
          background-size: 20px auto;
          background-position: center center;
          cursor: pointer;

          @include grid-media($large) {
            display: none;
          }
        }
      }
    }
  }

  form {
    .ta-has-results & {
      &.active {
        background: #fff;
        padding: 20px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99999;
        overflow: scroll;

        @include grid-media($large) {
          background: transparent;
          padding: 0;
          position: static;
          top: auto;
          left: auto;
          width: auto;
          height: auto;
          z-index: 0;
          overflow: auto;
        }
      }
    }
  }

  &--fieldswrapper {
    h2 {
      margin-top: 0;
      position: relative;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 1.14px;
      line-height: 18px;
      font-family: $themeFontBold;
      padding-bottom: 10px;
      margin-bottom: 35px;
      padding-top: 4px;
    }

    .ta-has-results & {
      display: none;

      @include grid-media($large) {
        display: block;
      }

      &.active {
        display: block;
      }

      &__scroll {
        display: block;
      }
    }
  }

  &--checklist {
    @include grid-column(12);

    @include grid-media($large) {
      @include grid-column(12);
    }

    @include grid-media($xlarge) {
      @include grid-column(6);
    }

    @include grid-media($xlarge) {
      .ta-has-results & {
        @include grid-column(12);
      }
    }

    &--lfs {
      @include grid-media($large) {
        @include grid-column(6);
      }

      @include grid-media($xlarge) {
        @include grid-column(3);
      }

      .checkbox {
        margin-right: 0;
      }
    }

    .other-options & {
      @include grid-column(12);
    }
  }

  .status-options,
  &--range {
    .ta-filter--checklist {
      @include grid-column(12);
      width: 100%;
      margin-left: 0;
    }

    @include grid-column(12);
    overflow: hidden;

    @include grid-media($large) {
      @include grid-column(6);
    }

    @include grid-media($xlarge) {
      @include grid-column(3);
    }

    @include grid-media($xlarge) {
      .ta-has-results & {
        @include grid-column(12);
      }
    }

    .lfs--nav & {
      @include grid-column(12);
    }
  }

  &--range {
    &.noAdvancedSearch {
      width: 100%;
      margin-top: 30px;

      &:last-child {
        border: none;
        margin-top: 0;
      }

      @include grid-media($large) {
        border-bottom: 1px color(grey3) solid;
        padding-bottom: 25px;

        &:last-child {
          margin-top: 25px;
        }
      }
    }
  }

  // padding: 0 16px;

  @include grid-media($xlarge) {
    .ta-has-results & {
      @include grid-column(12);
      @include layout-s-left;
    }
  }

  &--title {
    position: relative;
    display: block;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 14px;
    font-family: $themeFontMedium;
    font-weight: normal;

    &__error {
      display: inline-block;
      vertical-align: middle;
      margin-top: -3px;
      margin-left: 10px;
      color: color(red);
      font-family: $themeFontBook;
      font-size: 12px;
      text-transform: none;
    }

    @include grid-media($xlarge) {
      font-size: 18px;
      line-height: 18px;
      margin: 0 0 15px 0;
      letter-spacing: 1.29px;
      font-family: $themeFontBold;
      cursor: default;

      .ta-has-results & {
        font-size: 16px;
        letter-spacing: 1.14px;
        line-height: 16px;
        font-family: $themeFontMedium;
      }
    }
  }

  .isToggle {
    margin-bottom: 15px;

    .ta-has-results & {
      &:first-child {
        margin-top: 15px;
      }
    }

    .ta-filter--title {
      cursor: pointer;

      @include grid-media($large) {
        cursor: default;
      }

      @include grid-media($xlarge) {
        .ta-has-results & {
          cursor: pointer;
        }
      }

      .selectedvalue {
        position: absolute;
        width: 100px;
        display: block;
        top: 0;
        right: 25px;
        font-family: $themeFontBook;

        @include grid-media($large) {
          display: none;
        }

        @include grid-media($xlarge) {
          .ta-has-results & {
            display: block;
          }
        }
      }

      .ta-icon {
        position: absolute;
        top: -2px;
        right: 3px;
        display: block;
        height: 16px;
        width: 20px;
        background-size: auto;
        background-repeat: no-repeat;
        background-position: center center;
        transform: rotate(180deg);

        @include grid-media($large) {
          display: none;
        }

        @include grid-media($xlarge) {
          .ta-has-results & {
            display: block;
          }
        }
      }
    }

    .ta-filter--item {
      display: none;
      margin-bottom: 0;

      @include grid-media($large) {
        display: block;
      }

      @include grid-media($xlarge) {
        .ta-has-results & {
          display: none;
          margin-bottom: 0;
        }
      }
    }

    &.isActive {
      .ta-filter--item {
        display: block;
      }

      .ta-filter--title span {
        transform: rotate(0);
      }
    }
  }

  &--item {
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px color(grey3) solid;

    @include grid-media($large) {
      border: none;
    }

    @include grid-media($xxlarge) {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    @include grid-media($xlarge) {
      .ta-has-results & {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px color(grey3) solid;
      }
    }
  }

  .ta-row {
    @include grid-media($xxlarge) {
      margin-bottom: 50px;

      &.ta-filter--extra {
        margin-bottom: 50px;

        .ta-has-results & {
          margin-bottom: 50px;
        }
      }
    }

    @include grid-media($xxlarge) {
      margin-bottom: 40px;

      .ta-has-results & {
        margin-bottom: 0;
      }
    }
  }

  &--extra {
    display: block;
    padding: 10px 0 30px;

    @include grid-media($xlarge) {
      // display: none;
      padding-top: 0;
    }

    &__toggle,
    &__reset {
      @include grid-column(6);
      color: color(grey2);
      text-transform: uppercase;
      text-decoration: underline;
      font-size: 12px;
      font-family: $themeFontBook;
      line-height: 9.6px;
      letter-spacing: 0.86px;
      cursor: pointer;

      @include grid-media($xlarge) {
        margin-top: 0px;
      }

      @include grid-media($xlarge) {
        .ta-has-results & {
          @include grid-column(6);
        }
      }
    }

    &__toggle {
      @include grid-media($xlarge) {
        @include grid-column(2);
      }
    }

    &__reset {
      text-align: right;

      @include grid-media($xlarge) {
        @include grid-column(1);
        text-align: left;
      }

      @include grid-media($xlarge) {
        text-align: right;
      }

      &.single {
        @include grid-column(12);
      }
    }

    &__submit {
      @include grid-column(12);
      margin-top: 20px;
      text-align: center;

      @include grid-media($xlarge) {
        @include grid-column(6);
        margin-top: 0;
      }

      @include grid-media($xlarge) {
        .ta-has-results & {
          @include grid-column(12);
          margin-top: 20px;

          .ta-btn {
            max-width: 100%;
          }
        }
      }

      .view-all {
        text-align: center;
        text-decoration: underline;
        display: inline-block;
        margin: 25px;
        cursor: pointer;
        font-size: 12px;
        color: color(grey2);
        letter-spacing: 0.86px;
        text-transform: uppercase;
        transition: 0.3s ease color;

        &:hover {
          color: color(orange);
        }
      }
    }
  }

  &.noAdvancedSearch {
    // max-width: 360px;

    .advancedsearch {
      margin-bottom: 0;
      padding-right: 40px;
    }

    .ta-filter--title {
      font-size: 14px;
    }

    .ta-filter--range {
      padding-bottom: 0;

      .ta-filter--item {
        padding-bottom: 0;
        margin-bottom: 30px;
      }
    }

    .ta-filter--extra__reset {
      float: right;
      width: 30%;
      margin: 15px 0 0 0;
    }

    .ta-filter--extra__submit {
      width: 65%;
      max-width: 260px;
      margin-top: 0;

      button {
        width: 100%;
        max-width: 100%;
        height: 40px;
        padding-top: 3px;
        font-size: 14px;
        font-family: $themeFontBook;
        font-weight: 400;
      }
    }

    .ta-row {
      margin-bottom: 0;
      width: 100%;
      max-width: 360px;

      &.ta-filter--extra {
        margin-bottom: 20px;
      }
    }
  }

  .advancedsearch {
    display: none;
    padding-right: 0;
    margin-bottom: 0 !important;

    .ta-has-results & {
      // @include layout-s-left;
    }

    &.isActive {
      display: block;
    }

    // @include grid-media($xxlarge) {
    //   display: block;
    // }
  }
}
