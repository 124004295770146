@import "~assets/scss/settings";

.form {
  position: fixed;
  right: 160px;
  top: 300px;
  bottom: 0;
  width: 480px;
  font-family: $themeFontMedium;
  font-size: 12px;
  padding: 0 40px;
  line-height: 2;
  z-index: 6;
  letter-spacing: 0.5px;
  color: color(black);

  button {
    width: 100%;
    max-width: none;
  }
}
