@import '~assets/scss/settings';

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  transition: 0.3s visibility;
  z-index: 10;

  &.show {
    display: block;

    @include grid-media($touchtable) {
      display: none;
    }

    .content {
      opacity: 1;
      transform: translateX(0);
    }
  }

  $content-width: 640px;
  $content-width-collapsed: 160px;

  .content {
    position: absolute;
    right: 0;
    background: color(white);
    height: 100%;
    width: 368px;
    transform: translateX(100%);
    transition: 0.3s ease opacity, 0.3s ease transform;
    opacity: 0;

    .menu {
      margin: 0;
      @include px(5);
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      list-style-type: none;
      overflow-x: hidden;

      .item {
        font-size: 18px;
        letter-spacing: 1.29px;
        font-family: 'Gotham', sans-serif;
        text-align: center;

        &:not(:last-child) a {
          border-bottom: 1px solid color(green-400);
        }

        a {
          @include px(2);
          @include py(3);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            width: 160px;
          }

          span {
            display: inline-block;
            color: $black;
            text-transform: uppercase;
            margin-top: 16px;
            text-align: left;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .logoWrapper {
    text-align: center;
    padding: 32px 0;
    background-color: color(green-dark);
  }

  .bgDrop {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: $content-width;
  }

  &.collapsed {
    left: unset;

    .content {
      width: $content-width-collapsed;

      .menu {
        margin: 0;
        padding: 0;

        position: absolute;
        top: unset;
        left: 50%;
        transform: translateX(-50%);

        .item {
          &:not(:last-child) a {
            border-bottom: 1px solid color(white);
          }

          a {
            border-bottom: 1px solid color(white);
            @include py(4);
            @include px(2);
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            &.active {
              background-color: color(blue-light);
            }

            img {
              width: auto;
              height: 66px;
            }

            span {
              display: inline-block;
              @include m(0);
              @include mt(3);
              font-size: 14px;
            }
          }
        }
      }
    }

    .bgDrop {
      display: none;
    }
  }
}
