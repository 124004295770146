.package {
  border-radius: 0 0 3px 3px;
  box-shadow: 1px 3px 3px 0 rgba(#999, 0.3);
  &:hover {
    box-shadow: none;
  }

  &--buttons {
    display: none;
  }

  &--image {
    &__render {
      .ta-has-results & {
        height: 210px;
      }
    }
  }

  &--title {
    padding-top: 30px;

    h3 {
      font-weight: 700;
      font-family: $themeHeadings;
      font-size: 24px;
      text-transform: none;
      line-height: 30px;
      text-transform: normal;
      padding-bottom: 25px;

      span:first-child {
        text-transform: uppercase;
      }
    }
  }

  &--subtitle {
    display: none;

    .isLot & {
    }
  }

  &--details {
    font-size: 14px;

    &__label {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      text-transform: capitalize;
    }

    &__item {
      margin-left: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
    }
  }

  &--specs {
    padding: 16px;
    background-color: color(blue);
    display: flex;
  }

  &--price {
    &__from {
      font-family: 'Lato', sans-serif;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
      margin-bottom: 6px;
    }

    &__number {
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 24px;

      &.ta-no-price {
        color: transparent;

        &:after {
          content: ' Call agent';
          color: initial;
          font-size: 17px;
          margin-left: -7px;
        }
      }
    }
  }

  &--content {
    background: #fff;
    padding-bottom: 10px;

    .ta-has-results & {
      height: auto !important;
    }
  }

  &--amenities {
    margin-top: 17px;
    display: flex;

    > span {
      position: relative;
      font-family: 'Lato', sans-serif;
      padding-left: 0;
      margin-left: 40px;
      text-align: right;
      font-size: 17.5px;
      color: color(green-dark);
      font-weight: 700;

      &:first-of-type {
        margin-left: 20px;
      }
    }

    .ta-icon {
      position: absolute;
      left: 14px;
      top: -12px;
    }
  }

  &.isLot {
    box-shadow: none;
    .package {
      &--title {
        padding-top: 20px;

        h3 {
          position: relative;
          font-family: $themeHeadings;
          font-weight: 700;
          font-size: 26px;
          text-transform: none;
          padding-top: 70px;
          padding-bottom: 15px;

          &:after {
            content: 'Land for Sale';
            display: block;
            font-family: $themeHeadings;
            font-size: 48px;
            letter-spacing: -0.44px;
            color: color(green-dark);
            text-transform: uppercase;
            position: absolute;
            top: 0;
          }

          span:first-child {
            text-transform: inherit;
            color: color(green-dark);
          }

          span:last-child {
            color: color(green-400);
            font-weight: normal;
          }
        }
      }

      &--details {
        font-size: 18px;

        &__label {
          font-family: $themeHeadings;
          font-weight: 700;
          color: color(green-dark);
        }

        &__item {
          color: color(green-dark);
        }
      }
    }
  }
  &--summary {
    border-bottom: 1px solid color(green-400);

    &--title {
      h3 {
        margin-bottom: 5px;
        color: color(green-dark);
      }
      span {
        font-family: $themeHeadings;
        text-transform: none;
        font-weight: bold;
        font-size: 16px;
        color: color(green-dark);

        &.lot {
          display: block;

          &::after {
            display: none;
          }
        }

        &.package--summary--price__from {
          text-transform: lowercase;
          font-family: 'Lato', sans-serif;
          font-weight: normal;
          font-size: 14px;
          vertical-align: baseline;
        }

        &.package--summary--price__number {
          font-size: 18px;
          font-family: 'Lato', sans-serif;
          font-weight: 700;
        }
      }
    }

    &--image {
      width: 150px;
      position: relative;

      &__builder {
        position: absolute;
        display: block;
        width: 70px;
        left: 0;
        top: 0;
      }
    }

    &--content {
      width: calc(100% - 120px - 50px);
      padding-top: 0;

      &::after {
        height: 15px;
        width: 26px;
        top: 22px;
        background-size: contain;
      }
    }

    &--specs {
      font-family: $themeFontBold;
    }

    &--amenities {
      > span {
        position: relative;
        padding-right: 30px;
        margin-right: 20px;
        color: color(green-dark);
        font-weight: bold;
        font-family: 'Lato', sans-serif;
        font-size: 17.5px;

        span {
          margin-top: 4px;
        }

        &:first-of-type {
          margin-right: 25px;
          
          span {
            width: 32px;
            background-size: contain;
          }
        }

        &:nth-of-type(2) {
          margin-right: 12px;

          span {
            width: 15px;
            background-size: contain;
          }
        }

        &:nth-of-type(3) {
          span {
            width: 26px;
            background-size: contain;
          }
        }
      }

      .ta-icon {
        position: absolute;
        left: 20px;
        top: -5px;
      }
    }

    &.isSummary {
      padding-bottom: 15px;
    }
  }

  &--inclusions {
    h5 {
      border-bottom: 1px solid color(grey3);
      padding-bottom: 5px;
      text-transform: uppercase;
      font-family: $themeFontBold;
      letter-spacing: 1.6px;
    }
  }

  &.isDetail {
    box-shadow: 0 -15px 20px rgba(0, 0, 0, 0.2);
    z-index: 2;

    &:hover {
      box-shadow: 0 -15px 20px rgba(0, 0, 0, 0.2);
    }

    .package {
      &--title {
        h3 {
        }
      }
      &--price {
        &__from {
          display: block;
          margin-bottom: 8px;
        }

        &__number {
          font-family: $themeHeadings;
          font-size: 24px;
          font-weight: 700;
        }
      }

      &--amenities {
        margin-top: 20px;

        > span {
          font-size: 16px;
        }
      }

      &--details {
        font-size: 19px;
      }

      &--specs {
        height: 80px;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 14px;
        background: color(green-400);
      }
    }
  }

  &.packagesList {
    margin-left: 0;

    .package {
      &--image {
        width: 180px;
        max-width: none;
      }

      &--title {
        padding-top: 10px;

        h3 {
          font-size: 13px;
          font-weight: 700;
          padding-bottom: 5px;
        }

        span:not(.single):first-child:after {
          margin-left: 7px;
          margin-right: 7px;
          height: 20px;
          vertical-align: middle;
        }

        span:not(.isDetail):last-child {
          max-width: 57%;
        }
      }

      &--content {
        padding: 0 8px;
      }

      &--specs {
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 10px;
      }

      &--price {
        &__from {
          display: inline-block;
          float: none;
          margin-bottom: 0;
        }
        &__number {
          display: inline-block;
          float: none;
          font-size: 18px;
          margin-bottom: 0;
          margin-left: 5px;
        }
      }

      &--amenities {
        display: none;
      }

      &--details {
        font-size: 10px;

        &__label {
          font-weight: 600;
        }

        &__item {
          margin-left: 8px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  &--title {
    h3 {
      font-size: 30px;
      span {
        &:first-child {
          text-transform: none;

          &:not(.single) {
            &::after {
              background-color: color(green-400);
            }
          }
        }
      }
    }
  }
}
