@import "~traffic-property-app/assets/scss/settings";

.ad div {
  margin-top: 30px;
}

.title {
  overflow: auto;
  position: relative;
  display: block;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 14px;
  font-family: $themeFontMedium;
  font-weight: normal;

  @include grid-media($xlarge) {
    font-size: $font-size-lg;
    line-height: $font-size-lg;
    margin-bottom: 25px;
    letter-spacing: 1.29px;
    font-family: $appFontBold;
  }
}
