//
// Default custom styles
//
@import "settings";
@import "typography";

//
// Animate.css
//
@import "~animate.css/source/_base.css";
@import "~animate.css/source/fading_entrances/fadeInLeft.css";
// @import "~animate.css/source/sliding_entrances/slideInLeft.css";
// @import "~animate.css/source/zooming_entrances/zoomInLeft.css";

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/tables";
// @import "~bootstrap/scss/forms";
// @import "~bootstrap/scss/buttons";
// @import "~bootstrap/scss/transitions";
// @import "~bootstrap/scss/dropdown";
// @import "~bootstrap/scss/button-group";
// @import "~bootstrap/scss/input-group";
// @import "~bootstrap/scss/custom-forms";
// @import "~bootstrap/scss/nav";
// @import "~bootstrap/scss/navbar";
// @import "~bootstrap/scss/card";
// @import "~bootstrap/scss/breadcrumb";
// @import "~bootstrap/scss/pagination";
// @import "~bootstrap/scss/badge";
// @import "~bootstrap/scss/jumbotron";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import "~bootstrap/scss/media";
// @import "~bootstrap/scss/list-group";
// @import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
// @import "~bootstrap/scss/modal";
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/print";

// @import "swiper/swiper.scss";

html,
body,
#traffic-app {
  min-height: 100%;
  height: 100%;
  min-width: 100%;
  width: 100%;
}

button:focus {
  outline: 0;
}

.btn {
  text-transform: uppercase;

  &.btn-lg {
    letter-spacing: 0.6rem;
  }

  &.btn-link {
    border-bottom: 2px solid $black;
    padding-right: bsize(2) !important;
    padding-bottom: bsize(1) !important;

    &:hover {
      text-decoration: none;
    }
  }
}

label {
  text-transform: uppercase;
  font-weight: bold;
}

.form-control {
  text-transform: uppercase;

  .custom-control-label::after {
  }
}

a {
  transition: 0.3s ease all;
}

.package {
  .package--image {
    img + img {
      left: 0px;
    }
  }
}

.ta-filter--extra {
    &__reset {
      top: 40px;
      bottom: 0px;
      &::after {
        bottom: 0px;
      }
    }
    &__submit {
    .ta-btn__large{
      &.ta-btn__primary {
        background-color: transparent;
        border: none;
        height: auto;
      }
    }
  }
}
