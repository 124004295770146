@import "~traffic-property-app/assets/scss/settings";

.packageslider {
  padding: 80px 0 140px;
  background: color(lightblue);

  .ta-row {
    position: relative;
  }

  h4 {
    @include grid-column(12);
    font-size: $font-size-xl;
    line-height: 72px;
    font-family: $appFontBold;
    text-transform: uppercase;

    @include grid-media($small) {
      @include grid-column(10);
      @include grid-push(1);
    }
  }

  .swiper {
    &-container {
      position: static;
      float: left;
      width: 100%;
      padding-left: 16px;
      padding-right: 70px;
      overflow: hidden;

      @include grid-media($small) {
        @include grid-column(10);
        @include grid-push(1);
        padding-left: 0;
      }

      @include grid-media($large) {
        padding-right: 0;
      }

      @include grid-media($xxlarge) {
        padding-right: 0;
      }
    }

    &-wrapper {
      display: block;
    }

    &-button {
      &-next,
      &-prev {
        display: none;
        position: absolute;
        margin-top: 15px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 40px auto;
        width: 40px;
        transition: transform 0.3s ease;

        @include grid-media($large) {
          display: block;
        }
      }

      &-next {
        right: 50px;
        background-image: url(~traffic-property-app/assets/icons/others/arrow.svg);

        &:hover {
          transform: translateX(5px);
        }
      }
      &-prev {
        left: 50px;
        background-image: url(~traffic-property-app/assets/icons/others/arrow.svg);
        transform: rotate(180deg);

        &:hover {
          transform: translateX(5px) rotate(180deg);
        }
      }
    }
  }

  .package {
    margin: 0;

    @include grid-media($large) {
      width: auto;
    }

    &:first-child {
      margin-left: 0;
    }

    &--content {
      background: color(white);
    }

    &--subtitle {
      font-size: 12px;
    }

    &--title {
      h3 {
        font-size: $font-size-lg;
      }
    }

    &--specs {
      background: color(grey4);
    }

    &--details {
      font-size: 10px;
    }

    &--price {
      &__number {
        font-size: 24px;
      }
    }

    &--amenities {
      font-size: 12px;

      span {
        margin-left: 4px;
      }

      .ta-icon {
        margin-left: 0;
        width: 24px;
        background-size: auto 14px;
      }
    }

    &--buttons {
      .ta-btn {
        &:nth-child(1) {
          width: 65%;
          margin-left: 0;
        }
        &:nth-child(2) {
          width: calc(35% - 8px);
        }
      }
    }
  }
}
