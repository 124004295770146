@import "~assets/scss/settings";

.title {
  font-family: $themeHeadings;
  font-size: 24px;
  letter-spacing: 0.18px;
  font-weight: 700;
  @include pl(4);
}

.paragraph {
  font-family: $themeFontMedium;
  margin: 0 0 20px 0;
  font-size: 16px;
  @include mt(3);
  @include pl(4);
}

.disclaimer {
  position: fixed;
  right: 160px;
  bottom: 0;
  width: 480px;
  padding: 40px;
  padding-bottom: 15px;
  // font-family: $themeFontMedium;
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.5px;
  color: color(black);
  opacity: 0.9;
}

.thanks_message {
  background-color: color(grey4);
  padding: 22px 25px;

  .close_form {
    margin-top: 20px;
  }
}

.sendDetails {
  position: fixed;
  right: 160px;
  top: 300px;
  bottom: 0;
  width: 480px;
  font-family: $themeFontMedium;
  font-size: 12px;
  padding: 0 40px;
  line-height: 2;
  z-index: 2;
  letter-spacing: 0.5px;
  color: color(black);

  button {
    width: 100%;
    max-width: none;
  }
}
