@import "~assets/scss/settings";

.thanks_message {
  background-color: color(grey4);
  padding: 22px 25px;

  .close_form {
    margin-top: 20px;
  }
}

.content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: color(white);
  padding: 0 40px 40px;
  visibility: hidden;

  form {
    position: absolute;
    opacity: 0;
    transform: translateY(50px);
    transition: 0.5s ease all;
    width: 400px;
    background: #fff;
    // transition-delay: .2s;
  }

  button {
    width: 100%;
    max-width: none;
  }

  &.show {
    visibility: visible;

    form {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .field {
    @include grid-column(12);
    margin-top: 10px;

    label,
    input {
      display: block;
      width: 100%;
    }

    input {
      border: 1px solid color(grey3);
      padding: 10px 8px;

      &:focus {
        outline: 1px color(orange) solid;
      }
    }

    &.error {
      input {
        border-color: red;
      }
    }

    button {
      width: calc(100% + 32px);
      margin-top: 16px;
      margin-left: -16px;
    }
  }
}

.simpleKeyboard {
  position: fixed;
  background: #fff;
  bottom: 0;
  left: 0;
  width: calc(100vw - 640px);
  height: 52vh;
  padding: 100px 250px;
  opacity: 0;
  transform: translateY(50px);
  transition: 0.5s ease all;
  pointer-events: none;

  .show & {
    opacity: 1;
    transform: translateY(0);
    pointer-events: inherit;
  }

  .close_form {
    position: absolute;
    top: 50px;
    right: 255px;
  }
}

.icon {
  display: block;
  width: 14px;
  height: 14px;
  background-size: 14px;
  transform: rotate(180deg);
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;
}
