@import "~traffic-property-app/assets/scss/settings";

.container {
  margin-bottom: 24px;
  margin-top: 10px;
  @include grid-media($medium) {
    margin-top: 0;
  }

  .ta-has-results & {
    margin-bottom: 0;
  }

  @include grid-media($xxlarge) {
    margin-bottom: 50px;

    &.ta-filter--extra {
      margin-bottom: 50px;

      .ta-has-results & {
        margin-bottom: 50px;
      }
    }
  }

  .wrapper {
    @include grid-column(12);
    margin-bottom: 16px;
    border: 1px solid color(black);
    padding: 20px 25px;
    text-decoration: none;
    color: color(black);
    transition: all 0.3s ease;

    @include grid-media($medium) {
      @include grid-column(6);
      // width: 50%;
    }

    @include grid-media($large) {
      @include grid-column(4);
    }

    @include grid-media($xlarge) {
      @include grid-column(3);
    }

    &:hover {
      border-color: color(orange);
    }

    h3 {
      font-family: $themeFontBold;
      text-transform: uppercase;
      // letter-spacing: 0.81px;
      line-height: 14px;
      font-size: 14px;
      margin: 0;
      margin-bottom: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .neighbourhoodsOrEstates {
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    p {
      font-family: $themeFontBook;
      line-height: 14px;
      font-size: 14px;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
