@import "~traffic-property-app/assets/scss/settings";

.lfs {
  &--nav {
    @include grid-column(12);

    @include grid-media($large) {
      @include layout-s-left;
      padding-right: 0;
      // max-height: 850px;
      // overflow: hidden;
      // overflow-y: scroll;
    }

    h2 {
      position: relative;
      border-bottom: 1px color(black) solid;
      padding: 0 0 10px 0;
      width: 100%;
      margin: 0 0 10px 0;
      text-transform: uppercase;
      font-size: $font-size-lg;
      line-height: $font-size-lg;
      color: color(black);
      font-family: $appFontBold;

      @include grid-media($large) {
        width: calc(100% - 40px);
      }
    }

    &--filter {
      position: absolute;
      right: 0;
      top: 5px;
      border: none;
      outline: 0;
      box-shadow: none;
      width: 18px;
      height: 18px;
      background: transparent url(~traffic-property-app/assets/icons/others/filter.svg) no-repeat
        center center;
      background-size: 18px auto;
      cursor: pointer;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 0 20px;
    }

    li {
      display: block;
      margin: 0;
      border-bottom: 1px color(grey3) solid;

      &.lfs--navitem {
        margin: 0 0 16px 0;
      }

      &.active,
      &:hover {
        a {
          color: color(orange);
          &:after {
            transform: rotate(-90deg) translateY(5px);
          }
        }
      }
    }

    &--scroll {
      @include grid-media($large) {
        padding-right: 40px;
        overflow: hidden;
        overflow-y: scroll;
        max-height: 700px;
        margin-bottom: 30px;
      }
    }

    &--link {
      position: relative;
      display: block;
      padding: 12px 0;
      margin: 0;
      color: color(black);
      text-decoration: none;
      font-family: $themeFontBook;
      transition: all 0.3s ease;
      cursor: pointer;

      &:after {
        content: " ";
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        top: 15px;
        right: 0;
        transform: rotate(-90deg);
        background: transparent url(~traffic-property-app/assets/icons/others/chevron.svg) no-repeat
          right center;
        transition: all 0.3s ease;
      }

      &.lfs--navitem--thumb {
        &:after {
          display: none;
        }
      }
    }
  }

  &--navitem {
    position: relative;
    overflow: hidden;
    padding: 0 0 16px 0;
    cursor: pointer;

    &:after {
      content: " ";
      display: block;
      clear: both;
    }

    &.active,
    &:hover {
      .lfs--navitem--content {
        &:after {
          transform: rotate(-90deg) translateY(5px);
        }
      }
    }

    &.isActive {
      .lfs--navitem--wrapper {
        transform: translateX(-50%);
      }

      .lfs--navitem--content {
        &:after {
          opacity: 0;
        }
      }

      .lfs--navitem--c2a {
        &:before {
          opacity: 1;
        }
      }
    }

    &--wrapper {
      position: relative;
      width: 200%;
      padding-right: calc(100% - 30px);
      transition: all 0.5s ease;
      transform-origin: center center;

      &:after {
        content: " ";
        clear: both;
        display: block;
      }
    }

    &--content {
      position: absolute;
      z-index: 1;
      left: 100px;
      width: calc(50% - 70px);
      padding: 16px 30px 15px 16px;

      &:after {
        content: " ";
        display: block;
        clear: both;
        position: absolute;
        width: 25px;
        height: 25px;
        top: 15px;
        right: 30px;
        transform: rotate(-90deg);
        background: transparent url(~traffic-property-app/assets/icons/others/chevron.svg) no-repeat
          11px center;
        transition: all 0.3s ease;
      }
    }

    &--thumb {
      display: block;
      padding: 0;
      float: left;

      img {
        display: block;
        max-width: 100px;
        // margin-right: 16px;
      }
    }

    &--specs {
      float: left;
      width: 100%;
      margin-top: 10px;
      font-size: 10px;

      span {
        span {
          display: inline-block;
          font-family: $themeFontBold;
          width: auto;
          margin-right: 3px;
        }
      }
    }

    &--title {
      display: block;
      float: left;
      font-size: 14px;
      line-height: 14px;
      font-family: $themeFontBold;
      text-transform: uppercase;

      &:after {
        display: inline-block;
        clear: both;
        content: " ";
        margin: 0 10px;
      }
    }

    &--status {
      display: block;
      float: left;
      font-size: 14px;
      line-height: 14px;
      font-family: $themeFontBold;
      color: color(green);
      text-transform: uppercase;
    }

    &--landsize,
    &--frontage,
    &--packages {
      float: left;
      font-family: $themeFontBook;
      display: block;
    }

    &--landsize {
      width: 40%;
    }

    &--frontage {
      width: 35%;
    }

    &--packages {
      width: 25%;
      text-align: right;
    }

    &--c2a {
      position: absolute;
      z-index: 0;
      right: 0;
      top: 0;
      width: 50%;
      height: 100%;
      padding: 14px 16px 0 32px;
      background: color(grey4);

      &:before {
        content: " ";
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        top: 17px;
        left: 4px;
        transform: rotate(90deg);
        background: transparent url(~traffic-property-app/assets/icons/others/chevron.svg) no-repeat
          right center;
        transition: all 0.3s ease;
        opacity: 0;
      }

      > button,
      > a {
        float: left;

        &:nth-child(1) {
          width: 50%;
          margin-right: 8px;
        }

        &:nth-child(2) {
          width: 35%;
          margin-right: 8px;
        }

        &:nth-child(3) {
          width: calc(15% - 16px);
        }
      }

      a {
        display: block;
        height: 40px;

        &.ta-btn {
          line-height: 14px;
          padding: 13px 10px 0;
        }

        &.ta-btn__secondary {
          color: color(black);
          &:hover {
            color: color(white);
          }
        }

        span {
          display: block;
          height: 100%;
          background-size: 25px auto;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }

  &--c2as {
    @include grid-media($large) {
      padding-right: 40px;
    }

    .ta-btn {
      position: relative;
      max-width: 100%;
      margin-bottom: 16px;
      font-size: 16px;
      height: 48px;
      padding-top: 16px;
      font-family: $themeFontMedium;

      &:hover {
        background: transparent;
        border-color: color(buttonPrimary);
        color: color(black);
      }

      &:first-child {
        margin-bottom: 42px;
        height: 56px;
        padding-top: 22px;

        &:hover {
        }
      }

      span {
        position: absolute;
        left: 20px;
        top: 3px;
        width: 40px;
        height: 40px;
        background-size: 40px auto;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}
