@import "~traffic-property-app/assets/scss/settings";

.release {
  &--sorting {
    padding: 20px 0;
    &--label {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.86px;
      color: color(orange);
      font-family: $themeFontMedium;
      text-transform: uppercase;
    }

    label {
      margin-bottom: 0;
      margin-right: 10px;
    }

    &--select {
      display: inline-block;
      vertical-align: middle;
      overflow-x: hidden;
      padding-right: 15px;
      background: transparent url(~traffic-property-app/assets/icons/others/chevron.svg) no-repeat right 2px;
      background-size: 10px auto;
      cursor: pointer;

      select {
        display: block;
        border: none;
        box-shadow: none;
        background: none;
        outline: 0;
        width: calc(100% + 30px);
        margin: 0 0 0 0;
        padding: 0;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.86px;
        color: color(black);
        font-family: $themeFontMedium;
        text-transform: uppercase;
        -webkit-appearance: none;
        cursor: pointer;
      }
    }
  }
}