@import "~assets/scss/settings";

.sidebar_footer {
  z-index: 999;
  left: 20px;
  top: 20px;
  width: 250px;
  bottom: auto;

  @include grid-media($touchtable) {
    right: 630px;
    top: 55vh;
    left: auto;
    width: 480px;
  }
}

.sidebar {
  @include grid-media($touchtable) {
    background-color: color(white);
    padding: 15px 25px 25px;
    border-radius: 5px;
    z-index: 999;
  }

  .title {
    position: relative;
    color: #000;
    font-size: 12px;
    font-family: $font-family-sans-serif;
    letter-spacing: 1.2px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    // margin-top: 10px;

    @include grid-media($touchtable) {
      color: color(orange);
      font-family: "Verlag", sans-serif;
      font-size: 42px;
      font-weight: 300;
      margin-top: 40px;
    }

    @include grid-media($touchtable) {
      color: color(black);
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: "Gotham", sans-serif;
      font-weight: 300;
    }

    .reset {
      position: absolute;
      right: 0;
      top: 8px;

      @include grid-media($touchtable) {
        top: 0;
      }
    }
  }

  .subtitle {
    font-family: "RobotoSlab", sans-serif;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 26px;

    @include grid-media($touchtable) {
      display: none;
    }
  }

  .listgroup {
    margin-top: 20px;
    padding: 0;

    @include grid-media($touchtable) {
      margin-top: 20px;
      margin-bottom: 0px;
    }
  }

  .listitem {
    list-style-type: none;
    padding: 0;
    height: 40px;
    margin: 16px 0;
    transition: 0.5s ease all;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 1.7px;
    text-align: center;

    &:not(.active) {
      a,
      button {
        background: #D7D7D7 !important;
        color: color(grey2);
        border-color: transparent !important;

        @include grid-media($touchtable) {
          color: #000;
          background: color(grey1) !important;
        }
      }
    }

    @include grid-media($touchtable) {
      height: auto;
      padding: 2px 20px 2px 0;
      font-family: "Gotham", sans-serif;
      font-size: 18px;
      text-align: left;
    }

    &:last-child {
      margin-bottom: 30px;
    }


    button,
    a {
      display: block;
      text-transform: uppercase;
      letter-spacing: 1.7px;
      height: 40px;
      width: 100%;
      border: none;
      color: #000;
      outline: none;
      font-weight: bold;

      @include grid-media($touchtable) {
        height: auto;
        width: 100%;
        background: transparent !important;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        text-align: left;
        padding: 15px !important;
        color: #000;
      }
    }
  }

  a {
    color: color(black);
  }
}

.image {
  background-color: color(orange);
  background-position: center center;
  background-size: 100% auto;
  width: 35px;
  height: 35px;
  padding-top: 10px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  @include mr(3);
  display: none;

  @include grid-media($touchtable) {
    display: inline-block;
  }
}

// .colour {
//   &-red {
//     background-color: #E46613;
//   }
//   &-pink {
//     background-color: #F5A6B4;
//   }
// }
