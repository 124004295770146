@import "~traffic-property-app/assets/scss/settings";

.lfs {
  &--downloads {
    @include grid-column(12);
    // padding-top: 30px;
    margin-left: 0;
    margin-bottom: 40px;

    h4 {
      margin: 0 0 20px 0;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 2px;
      font-family: $themeFontMedium;
    }

    .ta-btn {
      display: block;
      max-width: none;
      width: auto;
      min-width: 120px;
      margin: 0 0 8px 0;
      margin-bottom: 8px;
      padding-right: 40px;
      text-align: left;
      font-family: $themeFontMedium;
      background-image: url(~traffic-property-app/assets/icons/others/download.svg);
      background-repeat: no-repeat;
      background-position: 94% center;
      background-size: 16px auto;

      &:hover {
        background-image: url(~traffic-property-app/assets/icons/others/download-inverted.svg);
      }

      @include grid-media($small) {
        display: inline-block;
        // min-width: 220px;
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }

      @include grid-media($xlarge) {
        min-width: 220px;
      }
    }
  }

  .ad {
    float: left;
    width: 100%;
    margin-top: 30px;
  }
}
