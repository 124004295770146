#traffic-app {
  padding: 0;
  overflow: hidden;
}

body {
  overflow: hidden;
  
  > h3 {
    display: none;
  }
}

.ta-container {
  margin: 0 auto;
  max-width: 100vw;
  padding: 0 $padding;
}
