/*--------------------------------------------------------------
5.0 Typography
--------------------------------------------------------------*/

@font-face {
  font-family: "Roboto";
  src: url("~assets/fonts/Roboto/Roboto-Regular.eot");
  src: url("~assets/fonts/Roboto/Roboto-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
    url("~assets/fonts/Roboto/Roboto-Regular.woff") format("woff"),
    url("~assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("~assets/fonts/RobotoSlab/RobotoSlab-Thin.eot");
  src: url("~assets/fonts/RobotoSlab/RobotoSlab-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Thin.woff2") format("woff2"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Thin.woff") format("woff"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("~assets/fonts/RobotoSlab/RobotoSlab-Light.eot");
  src: url("~assets/fonts/RobotoSlab/RobotoSlab-Light.eot?#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Light.woff2") format("woff2"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Light.woff") format("woff"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("~assets/fonts/RobotoSlab/RobotoSlab-Regular.eot");
  src: url("~assets/fonts/RobotoSlab/RobotoSlab-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Regular.woff2") format("woff2"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Regular.woff") format("woff"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("~assets/fonts/RobotoSlab/RobotoSlab-Bold.eot");
  src: url("~assets/fonts/RobotoSlab/RobotoSlab-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Bold.woff2") format("woff2"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Bold.woff") format("woff"),
    url("~assets/fonts/RobotoSlab/RobotoSlab-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Verlag";
  src: url("~assets/fonts/Verlag/Verlag-Black.eot");
  src: url("~assets/fonts/Verlag/Verlag-Black.eot?#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/Verlag/Verlag-Black.woff2") format("woff2"),
    url("~assets/fonts/Verlag/Verlag-Black.woff") format("woff"),
    url("~assets/fonts/Verlag/Verlag-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("~assets/fonts/Gotham/Gotham-Medium.eot");
  src: url("~assets/fonts/Gotham/Gotham-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/Gotham/Gotham-Medium.woff2") format("woff2"),
    url("~assets/fonts/Gotham/Gotham-Medium.woff") format("woff"),
    url("~assets/fonts/Gotham/Gotham-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("~assets/fonts/Gotham/Gotham-Bold.eot");
  src: url("~assets/fonts/Gotham/Gotham-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("~assets/fonts/Gotham/Gotham-Bold.woff2") format("woff2"),
    url("~assets/fonts/Gotham/Gotham-Bold.woff") format("woff"),
    url("~assets/fonts/Gotham/Gotham-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@import url("https://use.typekit.net/ulx2xli.css");