@import "~assets/scss/settings";

.title {
  font-family: $themeHeadings;
  font-size: 24px;
  font-weight: 700;
  @include pl(4);
}

.sendDetailsForm {
  top: 230px;
  bottom: unset;
}

:global(.package.isLot) {
  margin-bottom: 100px;
}
