.ta-gallery-wrapper {
  .ta-gallery {
    background: #fff;
    width: 57vw;
    height: 55vh;
    left: 50%;
    top: 50%;
    transform: translate(-80%, -50%);

    @include grid-media($touchtable) {
      width: 57vw;
      height: 59vh;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .close {
      width: 14px;
      height: 14px;
      background-size: 12px auto;
      top: auto;
      bottom: 25px;
      right: 40px;
      padding-right: 65px;
      background-position: right center;

      @include grid-media($touchtable) {
        width: 24px;
        height: 24px;
        background-size: 20px auto;
        top: auto;
        bottom: 30px;
        right: 40px;
        padding-right: 120px;
      }

      &:before {
        content: 'close';
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        font-size: 12px;
        font-family: 'Gotham', sans-serif;
        font-weight: 400;
        letter-spacing: 1.2px;
        margin-top: -4px;

        @include grid-media($touchtable) {
          font-size: 22px;
        }
      }
    }

    .floorplan {
      display: none;
    }

    .swiper {
      &-container {
        padding: 0;
      }

      &-imagewrapper {
        display: block;
        height: auto;
        padding: 65px 70px;

        @include grid-media($touchtable) {
          padding: 180px 130px;
        }
      }

      &-button {
        &-next,
        &-prev {
          .ta-icon {
            width: 24px;
            height: 24px;
            background-size: 18px auto;

            @include grid-media($touchtable) {
              width: 80px;
              height: 80px;
              background-size: 32px auto;
            }
          }
        }

        &-next {
          .ta-icon {
            margin-left: 103px;

            @include grid-media($touchtable) {
              margin-left: 43px;
            }
          }
        }

        &-prev {
          .ta-icon {
            margin-left: 103px;

            @include grid-media($touchtable) {
              margin-left: 43px;
            }
          }
        }
      }
    }
  }
}

.masterplan {
  &--title {
    color: color(green-dark) !important;
    font-family: $themeFontBold;
  }

  &--sub-title {
    color: color(green-dark) !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 16px !important;
    font-weight: 700;
    line-height: 1.625;
  }

  &--listgroup {
    li {
      border-bottom: 1px solid color(green-400) !important;
      font-family: 'Lato', sans-serif !important;
      color: color(green-dark) !important;
      font-weight: normal;
      font-size: 16px;

      &:first-of-type {
        border-top: none !important;
      }
    }
  }
}
