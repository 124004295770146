@import '~assets/scss/settings';

.ta-amenities {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100vw;

  .markers-map {
    width: 1330px !important;
  }

  &.ta-container {
    padding: 0;
    margin: 0;
  }

  .ta-row {
    display: block;
    flex-wrap: nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
  }

  &--nav {
    .ta-amenitiesWrapper & {
      position: fixed;
      z-index: 10;
      top: 0;
      right: 160px;
      height: 100vh;
      width: 480px;
      background: #fff;
      border-radius: 0px;
      padding: 60px 40px 20px 40px;
      box-shadow: -7px 0px 5px 0px rgba(0, 0, 0, 0.1);

      @include grid-media($touchtable) {
        top: 55vh;
        right: 4%;
        width: 480px;
        height: auto;
        padding: 60px 10px 20px 10px;
      }
    }

    &__title {
      .ta-amenitiesWrapper & {
        display: block;
        padding: 0;
        margin-top: 0;
        margin-bottom: 30px;
        font-size: 48px;
        font-family: $font-family-sans-serif;
        color: color(green-dark);
        font-weight: bold;

        @include grid-media($touchtable) {
          display: none;
        }
      }
    }

    &__desc {
      .ta-amenitiesWrapper & {
        display: block;
        font-family: $subheadings-font-family;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.625;
        color: color(green-dark);

        @include grid-media($touchtable) {
          display: none;
        }
      }
    }

    .swiper-container {
      // .ta-amenitiesWrapper & {
      display: none;
      // }
    }

    &__content {
      .ta-amenitiesWrapper & {
        ul {
          max-height: 850px !important;
        }
      }
    }

    &__button {
      position: relative;
      padding-left: 0;
      margin-left: 0;
      outline: 0;
      -webkit-appearance: none;
      height: 40px;

      &:focus {
        outline: none;
      }

      .ta-amenitiesWrapper & {
        .ta-amenities--nav__item {
          width: 32px !important;
          height: 32px !important;
          position: absolute;
          left: 0;
          top: 5px;
          font-weight: bold !important;
        }
      }
    }

    &__name {
      font-family: 'Lato', sans-serif !important;
      font-size: 16px !important;
      margin-left: 0 !important;
      padding-left: 55px;
      display: inline-block;

      @include grid-media($touchtable) {
        font-family: 'Verlag', sans-serif !important;
        font-weight: 700;
      }
    }

    &__ul {
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-top: 0 !important;
      overflow: unset !important;

      @include grid-media($touchtable) {
        padding-right: 20px !important;
        padding-left: 10px !important;
        overflow: auto !important;
      }

      &-wrapper {
        height: 84%;
      }
    }

    &__li {
      border-bottom: 1px #d8d8d8 solid;
      margin-top: 8px;
      padding-bottom: 18px !important;

      &.active {
        background: #effffe;

        .ta-amenities--nav__button {
          .ta-amenities--nav__item {
            background-color: #d8d8d8 !important;
            color: #000 !important;
          }
        }
      }
    }
  }

  &--map {
    .ta-amenitiesWrapper & {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 0;
      width: 100vw;
      height: 100vh;
      padding: 0;
    }
  }
}

.colour {
  &-orange {
    background-color: #f8a822 !important;
  }
  &-blue {
    background-color: #cfe9e8 !important;
  }
  &-red {
    background-color: #e46613 !important;
  }
  &-green {
    background-color: #b9da7d !important;
  }
  &-pink {
    background-color: #f5a6b4 !important;
  }
}
