@import "~traffic-property-app/assets/scss/settings";

.wrapper {
  margin-bottom: 40px;

  @include grid-media($xxlarge) {
    margin-bottom: 50px;
  }

  .package {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }
}
