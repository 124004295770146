.wrapper {
  width: 100%;
  height: 50px;
  position: relative;

  img {
    height: 100%;
  }

  .logo {
    padding: 10px 16px;
    max-width: 190px;
    max-height: 45px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }

  .builder {
    max-height: 100%;
  }
}

.builder {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 40px;
  width: auto;
  max-width: 120px;
}
