@import "~traffic-property-app/assets/scss/settings";

.backbutton {
  @include grid-column(12);
  float: none;
  position: relative;
  cursor: pointer;
  font-family: $themeFontMedium;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 15px;
  color: rgba(color(grey5), 0.45);
  padding: 8px 20px 0 0;
  margin-left: 16px;
  margin-bottom: 0;
  text-transform: uppercase;
  box-shadow: none;
  outline: 0;
  text-align: left;
  text-decoration: none;
  transition: all 0.3s ease;
  background: transparent;
  border: none;
  display: inline-block;
  width: auto;

  .ta-has-results & {
    margin-left: 0;
    margin-bottom: 40px;
  }

  .ta-townhomes &,
  .apartments & {
    margin-left: 0;
    margin-bottom: 40px;
  }

  &:hover {
    background-color: color(white);
    color: color(orange);
  }

  .ta-icon {
    display: inline-block;
    width: 16px;
    height: 11px;
    background-repeat: no-repeat;
    background-position: right -3px;
    transform: rotate(180deg);
    margin-bottom: -1px;
    margin-right: 16px;
    opacity: 0.45;
  }
}
