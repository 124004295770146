@import "~traffic-property-app/assets/scss/settings";

.not-found {
  text-align: center;

  h4 {
    color: color(orange);
    font-size: $font-size-xl;
    line-height: $font-size-xl;
    font-family: $appFontBold;
    padding-top: 40px;
    margin: 0 0 20px;
  }
}
