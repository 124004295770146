@import '../../traffic/variables';

.packagedetail {
  .package {
    position: fixed;
    right: 160px;
    top: 0;
    height: 100vh;
    width: 480px;
    padding: 40px;

    &.isDetail {
      padding-top: 15px;

      .package--title h3 {
        position: relative;
        padding-top: 70px;
        padding-bottom: 15px;

        &:after {
          // content: "Land for Sale";
          display: block;
          font-family: $themeFontBold;
          font-size: 48px;
          letter-spacing: -0.44px;
          color: color(green-dark);
          text-transform: uppercase;
          font-weight: 700;
          position: absolute;
          top: 0;
        }

        span {
          &:first-child,
          &:last-child,
          &:not(.single):last-child {
            width: auto;
            max-width: none;
            color: color(green-dark);
            font-size: 24px;
            font-weight: 700;
            text-transform: uppercase;
          }

          &:not(.single):last-child {
            color: color(green-400);
            font-weight: 500;
            text-transform: none;
          }

          &:not(.single):first-child:after {
            margin: -4px 25px 0;
          }
        }
      }

      .package--inclusions {
        margin-top: 150px;

        h5 {
          font-size: 16px;
          font-family: $themeFontBold;
          font-weight: 700;
          color: color(green-dark);
          letter-spacing: 0;
          border-bottom: 1px solid color(green-400);
          padding-bottom: 8px;
        }

        p {
          ul {
            column-count: unset;

            li {
              font-family: $themeHeadings;
              font-weight: 400;
              font-size: 18px;
              line-height: 28px;
              list-style-type: none;
              position: relative;
              margin-left: 14px;
              color: color(green-dark);
              margin-bottom: 0;

              &::after {
                position: absolute;
                left: -24px;
                top: 15px;
                width: 4px;
                height: 4px;
                background-color: color(green-dark);
                border-radius: 50%;
                content: ' ';
              }
            }
          }
        }
      }

      .package--content {
        .package--details {
          &__label {
            font-size: 18px;
            font-family: $themeFontBold;
            font-weight: 700;
            color: color(green-dark);
          }

          &__item {
            font-size: 18px;
            font-family: $themeFontBold;
            font-weight: 300;
            color: color(green-dark);
          }
        }
      }

      .package--specs {
        padding-top: 8px;
        padding-bottom: 8px;
        display: flex;
        justify-content: space-between;
        background-color: color(blue-dark);

        .package--amenities {
          align-items: flex-start;
        }
      }
    }
  }

  &--content {
    position: fixed;
    left: 80px;
    top: 0;
    width: 1200px;
    height: 100vh;
    margin-left: 0;
  }

  &--map {
    display: none;
  }

  &--gallery {
    &__left {
      padding-right: 0;

      img {
        padding: 0;
      }
    }
    &__right {
      width: 100%;
      top: auto;
      bottom: -110px;
      height: 160px;
      padding: 0 1.5rem;
    }

    &__thumb {
      display: inline-block;
      height: 160px;
      width: calc(100% / 4 - 15px);
      margin-left: 20px;
      box-shadow: 0 0 5px rgba(110, 83, 83, 0.31);

      &:first-child {
        margin-left: 0;
        height: 160px;
      }
    }
    &__builder {
      right: 0;
    }
  }
}

.packageslider {
  display: none;
}

.socialsharing {
  display: none;
}

.react-simple-keyboard {
  border-radius: 0;
  background: #fff;

  &.hg-theme-default {
    .hg-button {
      box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.85);
      height: 50px;
      font-weight: 700;
    }
  }
}

.contentfooter {
  background-color: color(white-off);

  h1 {
    color: color(green-dark);
  }
}
