@import "~traffic-property-app/assets/scss/settings";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scaleIn {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.ta-gallery {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: color(black);

  &.active {
    animation: fadeIn 0.5s ease;

    .swiper-imagewrapper img {
      animation: scaleIn 0.7s ease;
    }
  }

  .swiper {
    &-container,
    &-wrapper {
      height: 100vh;
    }

    &-imagewrapper {
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 150px 16px;

      @include grid-media($large) {
        padding: 150px 60px;
      }

      @include grid-media($xlarge) {
        padding: 150px 200px;
      }

      @include grid-media($xxlarge) {
        padding: 200px;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
      }
    }

    &-button {
      &-next,
      &-prev {
        .ta-icon {
          display: inline-block;
          width: 28px;
          height: 28px;
          background-size: 328px 0px auto;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      &-next {
        display: none;
        right: 16px;
        background: none;

        @include grid-media($large) {
          display: block;
        }

        @include grid-media($xlarge) {
          right: 120px;
        }
      }

      &-prev {
        display: none;
        transform: rotate(180deg);
        left: 16px;
        background: none;

        @include grid-media($large) {
          display: block;
        }

        @include grid-media($xlarge) {
          left: 120px;
        }
      }
    }

    &-pagination {
      top: 50px;
      bottom: auto;
      left: 16px;
      height: auto;
      width: 100px;
      color: color(white);
      text-align: left;
      font-family: $themeFontMedium;
      font-size: 14px;
      text-transform: uppercase;

      @include grid-media($large) {
        left: 60px;
      }

      @include grid-media($xxlarge) {
        left: 150px;
      }
    }
  }

  .close,
  .share,
  .floorplan {
    display: block;
    position: absolute;
    z-index: 1;
    top: 50px;
  }

  .close,
  .share .ta-icon,
  .floorplan .ta-icon {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: right center;
  }
  

  .close {
    right: 16px;
    background-size: 16px auto;
    background-position: center center;
    cursor: pointer;
    z-index: 2;

    @include grid-media($large) {
      right: 60px;
    }

    @include grid-media($xxlarge) {
      right: 150px;
    }
  }

  .share {
    display: none;
    z-index: 1;
    right: 200px;
    top: 50px;
    color: color(white);
    font-family: $themeFontMedium;
    text-transform: uppercase;
    font-size: 10px;

    .ta-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      background-size: 24px auto;
    }
  }

  .floorplan {
    display: block;
    text-align: center;
    z-index: 2;
    left: 50%;
    top: auto;
    bottom: 50px;
    transform: translateX(-50%);
    color: color(white);
    font-family: $themeFontMedium;
    text-transform: uppercase;
    font-size: 10px;
    cursor: pointer;

    .ta-icon {
      display: block;
      vertical-align: middle;
      margin: 0 auto 15px;
      background-size: 24px auto;
    }
  }
}
