@import '~traffic-property-app/assets/scss/settings';

.range {
  &--select {
    margin-bottom: 15px;
  }
  .checkbox {
    display: inline-block;
    width: 46px;
    height: 32px;

    @include grid-media($xxlarge) {
      height: 40px;
      width: 64px;
    }

    &:last-child {
      width: 40px;
    }

    &.min,
    &.max {
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        background: color(white);
        transition: all 0.3s ease;
      }
    }

    &.min {
      &:after {
        background: color(lightblue);
      }
    }

    &.max {
      &:after {
        content: ' ';
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        background: color(white);
      }
    }

    &.max:not(.min) {
      &::before {
        background-color: #d9eff7;
        content: ' ';
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        z-index: 1;
        width: 40px;
        height: 40px;
        border-radius: 0 20px 20px 0;
      }
    }

    label {
      position: relative;
      z-index: 1;
      display: block;
      width: 32px;
      height: 32px;
      padding: 8px 0 0 0;
      color: color(grey5);
      text-align: center;
      font-family: $themeFontMedium;
      font-size: 15px;
      cursor: pointer;
      transition: all 0.3s ease;
      border: 1px transparent solid;

      @include grid-media($xxlarge) {
        width: 40px;
        height: 40px;
        padding: 11px 0 0 0;
      }

      &:hover {
        border-color: color(grey5);
      }

      &.checkall {
        border: 1px color(black) solid;

        &:hover {
          border-color: color(grey5);
          background: color(grey5);
          color: color(white);
        }
      }
    }

    input {
      display: none;

      &:checked {
        + label {
          background: color(grey5);
          color: color(white);

          &:hover {
            background: color(white);
            color: color(grey5);
          }

          &.checkall {
            &:hover {
              border-color: color(grey5);
              background: color(grey5);
              color: color(white);
            }
          }
        }
      }
    }
  }
}
