@import "~traffic-property-app/assets/scss/settings";

.package {
  @include grid-column(12);
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.3s ease, opacity 0.1s ease;

  @include grid-media($medium) {
    @include grid-column(6);
  }

  @include grid-media($xlarge) {
    @include grid-column(6);
  }

  @include grid-media($xxlarge) {
    @include grid-column(4);
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    // transform: scale(1.01);
    .package--image {
      opacity: 0.7;

      &__render {
        // transform: scale(1.01);
      }
    }
  }

  &--image {
    display: block;
    overflow: hidden;
    background: white;
    transition: opacity 0.35s ease;
    position: relative;

    &__render {
      max-width: 100%;
      display: block;
      transition: transform 0.35s ease;
    }

    &__flag {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 10px;
      text-transform: uppercase;
      color: #000;
      background: color(blue);
      padding: 10px 10px 6px;
      font-family: $themeFontBold;
      letter-spacing: 2px;
    }
  }

  &--content {
    display: block;
    padding: 0 16px;
    background: color(grey4);
  }

  &--subtitle {
    display: block;
    padding: 20px 0 13px;
    color: color(orange);
    text-transform: uppercase;
    letter-spacing: 0.81px;
    line-height: 13px;
    font-size: 13px;
    font-family: $themeFontBold;
    height: 60px;

    &:hover {
      color: color(orange);
    }

    &:after {
      content: " ";
      display: block;
      clear: both;
    }

    &__type {
      display: inline-block;
      float: left;
      padding-top: 5px;
      // color: color(orange);
    }

    &__options {
      float: right;
      text-align: right;

      .ta-icon {
        display: inline-block;
        vertical-align: middle;
        width: 25px;
        height: 25px;
        margin-left: 4px;
        background-size: 24px auto;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.35;
      }
    }
  }

  &--title {
    position: relative;
    display: block;
    color: color(black);

    &:hover {
      color: color(black);
    }

    h3 {
      font-size: $font-size-lg;
      line-height: $font-size-lg;
      font-family: $appFontBold;
      text-transform: uppercase;
      margin-bottom: 0;
      padding-bottom: 15px;
    }

    span {
      display: inline-block;
      vertical-align: top;

      &.available {
        color: color(available);
      }
      &.hold {
        color: color(hold);

        &.hasAfter {
          &:after {
            .ta-has-results & {
              display: none;
            }
          }
        }
      }
      &.sold {
        color: color(sold);
      }
      &.builders {
        color: color(builders);
      }

      &:not(.single) {
        &.hasAfter,
        &:first-child {
          max-width: 45%;
          &:after {
            content: " ";
            display: inline-block;
            overflow: hidden;
            height: 32px;
            width: 1px;
            margin: -4px 10px 0;
            background: color(grey3);
            vertical-align: top;
          }
        }
      }

      &:last-child {
        max-width: 62%;
      }
    }

    .ta-icon {
      position: absolute;
      top: 5px;
      right: 0;
      width: 20px;
      height: 30px;
      background-size: 20px auto;
      background-repeat: no-repeat;
      background-position: center center;
      cursor: pointer;
    }
  }

  &--details {
    display: block;
    padding-bottom: 15px;
    color: color(black);
    font-size: 12px;
    line-height: 12px;

    &:hover {
      color: color(black);
    }

    &__item {
      display: inline-block;
      margin: 0 0 0 12px;

      &:first-child {
        margin-left: 0;
      }
    }

    &__label {
      font-family: $themeFontBold;
    }
  }

  &--specs {
    display: block;
    padding: 12px 16px 7px;
    margin-bottom: 10px;
    background: color(white);
    color: color(black);

    &:hover {
      color: color(black);
    }

    &:after {
      content: " ";
      clear: both;
      display: block;
    }
  }

  &--price {
    float: left;
    display: block;

    &__from {
      display: block;
      margin: 0 0 3px 0;
      font-size: 10px;
      letter-spacing: 0.08px;
      line-height: 10px;
      font-family: $themeFontMedium;
      text-transform: uppercase;
    }

    &__number {
      display: block;
      font-size: 28px;
      line-height: 28px;
      font-family: $appFontBold;

      @include grid-media($small) {
        font-size: $font-size-lg;
        line-height: $font-size-lg;
      }
    }
  }

  &--amenities {
    float: right;
    display: block;
    padding-top: 2px;
    color: color(orange);
    font-size: 14px;
    font-family: $themeFontMedium;
    line-height: 14px;

    @include grid-media($small) {
      font-size: 16px;
      line-height: 16px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      margin-left: 4px;

      @include grid-media($small) {
        margin-left: 7px;
      }
    }

    .ta-icon {
      height: 40px;
      width: 30px;
      margin: 0;
      background-repeat: no-repeat;
      background-size: auto 14px;
      background-position: center 12px;

      @include grid-media($small) {
        background-size: auto 16px;
        background-position: center 10px;
      }
    }
  }

  &--inclusions {
    margin: 30px 0;
    h5 {
      margin: 0 0 20px 0;
      font-size: 16px;
      font-family: $themeFontMedium;
    }

    ul {
      padding: 0;
      margin: 0 0 20px 16px;

      &:after {
        content: " ";
        display: block;
        clear: both;
      }

      @include grid-media($small) {
        column-count: 2;
        column-gap: 32px;
      }

      li {
        margin: 0 0 10px 0;
        // float: left;
        // width: calc(50% - 15px);
        font-size: 14px;
        font-family: $themeFontBook;

        // &:nth-child(odd) {
        //   margin-right: 30px;
        // }
      }
    }
  }

  &--buttons {
    padding-bottom: 16px;
    margin: 0;

    &:after {
      content: " ";
      clear: both;
      display: block;
    }

    .ta-btn:nth-child(1) {
      // position: relative;
      width: 75%;
    }

    .ta-btn {
      float: left;
      display: block;
      max-width: none;
      margin-left: 8px;

      &:nth-child(1) {
        width: 40%;
        margin-left: 0;
        // width: calc(60% - 16px);
        //
        // @include grid-media($medium) {
        //   width: 75%;
        // }
      }

      &:nth-child(2) {
        width: calc(25% - 8px);
        // margin-left: 16px;
        // width: 40%;
        //
        // @include grid-media($medium) {
        //   margin-left: 8px;
        //   width: calc(25% - 8px);
        // }
      }

      &:nth-child(3) {
        width: calc(35% - 8px);
      }

      &:nth-child(4) {
        width: 100%;
        margin-top: 8px;
        margin-left: 0;
      }
    }
  }
  &:not(.isDetail) {
    .package--title h3 {
      margin: 0;

      span {
        &:last-child {
          text-overflow: ellipsis;
          white-space: nowrap;
          // overflow: hidden;
        }
      }
    }
  }

  &.isDetail {
    @include grid-column(12);

    &:hover {
      box-shadow: none;
      transform: scale(1);
      .package--image {
        opacity: 1;

        &__render {
          transform: scale(1);
        }
      }
    }

    @include grid-media($large) {
      @include layout-m-left;
      padding-top: 0;
    }

    // @include grid-media($xxlarge) {
    //   @include layout-m-left;
    //   padding-top: 0;
    // }

    &:hover {
      border-color: transparent;
      .package--image {
        opacity: 1;
      }
    }

    .package {
      &--subtitle {
        color: color(black);
        font-size: 16px;
        letter-spacing: 1.14px;
        line-height: 16px;
        font-family: $themeFontMedium;
        padding-bottom: 25px;
        padding-top: 0;
      }

      &--title {
        color: color(orange);

        h3 {
          margin: 0;
          font-size: $font-size-lg;
          line-height: $font-size-lg;

          @include grid-media($small) {
            font-size: $font-size-xl;
            line-height: $font-size-xl;
          }

          &:after {
            content: " ";
            display: block;
            clear: both;
          }

          span {
            &:first-child {
              @include grid-media($large) {
                max-width: 50%;
              }
            }

            &:not(.single) {
              &:last-child {
                width: 65%;
              }
            }
          }
        }
      }

      &--buttons {
        .ta-btn {
          &:nth-child(1) {
            width: calc(60% - 16px);

            @include grid-media($medium) {
              width: 75%;
            }
          }

          &:nth-child(2) {
            margin-left: 16px;
            width: 40%;

            @include grid-media($medium) {
              margin-left: 8px;
              width: calc(25% - 8px);
            }
          }
        }
      }

      &--content {
        background: transparent;
        padding: 0;
      }

      &--details {
        @include grid-media($small) {
          font-size: 14px;
          line-height: 16px;
        }
      }

      &--specs {
        background: color(grey4);
        margin-bottom: 16px;
      }

      &--price {
        margin-top: 5px;

        &__from {
          display: inline-block;
          vertical-align: top;
          margin: 3px 10px 0 0;
        }

        &__number {
          @include grid-media($small) {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }

      &--amenities {
        @include grid-media($small) {
          // margin-right: 30px;
        }
      }

      &--buttons {
        .ta-icon {
          display: block;
          position: absolute;
          right: 5px;
          top: 10px;
          width: 20px;
          height: 20px;
          background-size: 16px auto;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &__downloads {
          .ta-btn {
            position: relative;
            &:nth-child(1) {
              width: calc(60% - 16px);
            }
            &:nth-child(2) {
              width: 40%;
              margin-left: 16px;
            }
          }
        }
      }

      &--inclusions {
        display: none;

        @include grid-media($large) {
          display: block;
          margin-right: 0;
        }
      }
    }
  }

  &.isLot {
    @include grid-column(12);
    @include grid-collapse;
    background: transparent;
    margin-bottom: 40px;

    &:hover {
      box-shadow: none;
      transform: scale(1);
      .package--image {
        opacity: 1;

        &__render {
          transform: scale(1);
        }
      }
    }

    .package {
      &--content {
        background: transparent;
      }

      &--title {
        h3 {
          font-size: $font-size-lg;
          line-height: $font-size-lg;
          font-family: $appFontBold;
          margin: 0;
          margin-bottom: 5px;

          span {
            max-width: none;
            color: color(black);
            font-size: 20px;

            &:nth-child(2) {
              color: color(green);
            }
          }
        }
      }

      &--details {
        margin-bottom: 10px;
      }

      &--buttons {
        .ta-btn {
          &:nth-child(1) {
            width: 100%;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: calc(50% - 8px);
            margin-left: 0;
            margin-top: 16px;
          }

          &:nth-child(2) {
            margin-right: 16px;
          }
        }
      }
    }
  }

  &--summary {
    @include grid-column(12);
    border-bottom: 1px color(grey3) solid;
    margin-bottom: 16px;
    padding: 0 0 12px 0;

    &:hover {
      .package--summary--content {
        &:after {
          transform: translateX(0) rotate(-90deg);
        }
      }
    }

    &--image {
      width: 100px;
      float: left;

      &__render {
        min-height: 72px;
      }

      &__builder {
        display: none;
      }
    }

    &--content {
      position: relative;
      float: left;
      width: calc(100% - 120px);
      margin: 0 0 0 20px;
      padding: 10px 0 0;

      &:after {
        content: " ";
        display: block;
        position: absolute;
        width: 15px;
        height: 26px;
        right: 0;
        top: 15px;
        transform: translateX(-5px) rotate(-180deg);
        background: transparent url(~traffic-property-app/assets/icons/others/chevron.svg) no-repeat
          center center;
        background-size: cover;
        transition: all 0.3s ease;
      }
    }

    &--title {
      h3 {
        margin: 0;
        margin-bottom: 10px;
      }

      span {
        display: inline-block;
        color: color(black);
        font-size: 14px;
        line-height: 14px;
        font-family: $themeFontBold;
        text-transform: uppercase;

        .package--summary--price__from {
          font-size: 10px;
          margin: 0 5px 0 0;
          vertical-align: middle;
        }

        &.lot {
          &:after {
            content: " ";
            display: inline-block;
            height: 20px;
            width: 1px;
            margin: 0 10px;
            background: color(grey3);
            vertical-align: middle;
          }
        }
      }
    }

    &--price {
      &__from {
        font-size: 10px;
      }
    }

    &--specs {
      color: color(orange);
      font-family: $themeFontBook;
      font-size: 14px;

      .ta-icon {
        display: inline-block;
        vertical-align: middle;
        height: 25px;
        width: 25px;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }

    &--amenities {
      > span {
        display: inline-block;
        margin-right: 12px;
        vertical-align: middle;
      }

      .ta-icon {
        width: 20px;
        margin-bottom: 1px;
      }
    }
  }
}
