@import "~traffic-property-app/assets/scss/settings";

.results {
  -webkit-overflow-scrolling: touch;
  @include grid-column(12);
  // padding: 0 16px;

  @include grid-media($xlarge) {
    .ta-has-results & {
      @include grid-column(12);
      @include layout-s-right;
    }
  }

  &--sorting {
    @include grid-column(12);
    margin-bottom: 50px;

    h4 {
      display: inline-block;
      margin: 0 20px 0 0;

      &.no-results {
        display: block;
        text-align: center;
        font-size: $font-size-lg;
        margin-top: 7px;
      }
    }
  }

  &--sorting {
    margin: 0 0 50px 0;
    @include grid-column(12);

    h4 {
      display: inline-block;
      margin-right: 25px;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.91px;
      color: color(orange);
      font-family: $themeFontMedium;
      font-weight: normal;
      text-transform: uppercase;
    }

    a {
      display: inline-block;
      margin-right: 30px;
      color: color(black);
      opacity: 0.3;
      text-decoration: none;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 0.91px;
      text-transform: uppercase;
      font-family: $themeFontMedium;

      transition: opacity 0.3s ease;

      &.active,
      &:hover {
        opacity: 1;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .ad {
    @include grid-column(12);
  }
}
