@import "~traffic-property-app/assets/scss/settings";

.container {
  min-height: 541px;
  // min-width: 1648px;
  // margin-top: -21px;
  // overflow: auto;

  .title {
    @include grid-column(12);
    position: relative;
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 14px;
    font-family: $themeFontMedium;
    font-weight: normal;

    @include grid-media($xlarge) {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 15px;
      letter-spacing: 1.29px;
      font-family: $themeFontBold;
    }
  }

  .paragraph {
    position: relative;
    display: block;
    font-size: 14px;
    font-family: $themeFontBook;
    font-weight: normal;

    @include grid-media($xlarge) {
      position: relative;
      display: block;
      font-size: 18px;
      font-family: $themeFontBook;
      font-weight: normal;
    }
  }

  .package {
    width: 100%;
    // @include grid-column(12);
    position: relative;

    // @include grid-media($small) {
    //   @include grid-column(8);
    // }
    //
    // @include grid-media($medium) {
    //   @include grid-column(6);
    // }
    //
    // @include grid-media($large) {
    //   @include grid-column(5);
    // }
    //
    // @include grid-media($xlarge) {
    //   @include grid-column(3);
    // }

    .item {
      width: 100%;
      margin-left: 0;
    }

    @include grid-media($large) {
      .toolbar {
        transform: translateY(20px);
        opacity: 0;
        transition: opacity, transform 0.3s ease;

        button {
          opacity: 0.7;
          transition: opacity 0.3s ease;

          &:hover {
            opacity: 1;
          }

          :global(.ta-icon) {
            transition: transform 0.3s ease;
            transform: scale(0.6);
          }
        }
      }

      &:hover .toolbar {
        transform: translateY(0);
        opacity: 1;

        button {
          :global(.ta-icon) {
            transform: scale(1);
          }
        }
      }
    }
    // @include grid-media($large) {
    //   @include grid-column(10);
    //   @include grid-push(1);
    // }
    //
    //
    .toolbar {
      width: 100%;
      // @include grid-column(12);
      text-align: right;
      margin-bottom: 8px;

      button {
        cursor: pointer;
        border: 0;
        background: transparent;
        padding: 0;

        :global(.ta-icon) {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 20px;
        }
      }

      // .trash {
      //   position: absolute;
      //   right: 0;
      // }
    }
  }
}
