@include grid-media($xxxlarge) {
  .ta-btn {
    height: 72px;
    color: color(green-dark);
    background-color: color(orange);
    border-radius: 3px;
    border: 1px solid color(orange);

    &::after {
      color: color(green-dark);
    }
  }

  .backbutton {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 80px;
    padding-left: 0;
    margin-left: 0;
    font-size: 0;
    line-height: 0;
    z-index: 1;
    background-color: color(blue-dark);

    &:hover {
      background: color(green-400);
    }

    .ta-icon {
      display: none;
    }

    &::after {
      content: '';
      background-image: url(~traffic-property-app/assets/icons/others/chevron.svg);
      display: block;
      position: absolute;
      color: #000;
      font-weight: normal;
      top: 50%;
      left: calc(50% - 13px);
      width: 15px;
      height: 26px;
      background-size: cover;
      font-size: 30px;
    }

    &__wrapper {
      background: color(blue);
    }
  }
}

.qr-button {
  position: relative;

  &::after {
    background-image: url(~traffic-property-app/assets/icons/others/phone.svg);
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 30px;
    top: calc(50% - 15px);
    left: 30px;
  }
}
