@include grid-media($xxxlarge) {
  .ta-filter {
    position: fixed;
    left: 1280px;
    top: 0;
    width: 480px;
    height: 100vh;
    margin-left: 0;
    padding: 40px;
    box-shadow: 0 -15px 20px rgba(0, 0, 0, 0.2);

    .range {
      .checkbox {
        input:checked + label {
          text-transform: capitalize;
          border-radius: 50%;
          font-family: $themeFontMedium;
          font-weight: 500;
        }
        label {
          border-radius: 50%;
          padding: 10px 0 0 0;
          text-transform: none;
        }
        &.min {
          &::after {
            background-color: color(blue-mid);
            border-radius: 20px 0 0 20px;
          }
        }
      }
    }

    .lfs--nav & {
      padding-top: 140px;
      // padding-top: 80px;
    }

    &--extra {
      position: relative;

      &__submit {
        width: 100%;
        max-width: 100%;
        margin-top: 0 !important;
        
        button {
          height: 72px;
        }
      }

      $reset-button-area: 20px;
      &__reset {
        position: absolute;
        left: -$reset-button-area;
        top: 100px;
        width: auto !important;
        font-size: 16px;
        color: color(green-dark);
        font-weight: 500;
        letter-spacing: 0.05em;
        text-decoration: none;
        padding-top: #{$reset-button-area / 2};
        padding: $reset-button-area;

        &::after {
          content: ' ';
          position: absolute;
          bottom: 10px;
          left: $reset-button-area;
          width: calc(100% - #{$reset-button-area * 2});
          height: 1px;
          background: #000;
        }
      }
    }

    .ta-has-results & {
      position: fixed;
      left: 1280px;
      top: 0;
      width: 480px;
      height: 100vh;
      margin-left: 0;
      padding: 40px;

      &--item {
        border: none;
      }

      .select {
        width: 176px;
        height: 48px;
        border: 1px solid color(green-dark);
        border-radius: 5px;

        &::after {
          top: 14px;
        }

        select {
          height: 48px;
          padding: 0 0 0 5px;
          font-weight: 700;
          font-family: $themeFontBold;
          color: color(green-dark);
          font-size: 14px;
          letter-spacing: 1.4px;
          text-transform: uppercase;
        }
      }

      .isToggle {
        &:first-child {
          margin-top: -5px;
        }
        .ta-filter--item {
          display: block;
          overflow: hidden;
        }

        .ta-filter--title {
          .selectedvalue,
          .ta-icon {
            display: none;
          }
        }
      }

      .ta-filter {
        &--range {
          &.ta-land.size,
          &.ta-price {
            margin-top: 110px;

            .ta-filter--item {
              margin-bottom: 0;
            }
          }

          &.ta-release,
          &.ta-builder {
            position: absolute;
            top: 170px;
            width: calc(100% - 82px);

            .select {
              width: 100%;
            }
          }
        }
        &--extra {
          &__toggle {
            display: none;
          }
        }
      }

      .ta-row {
        margin-bottom: 20px;
      }
    }

    .advancedsearch {
      display: block;
    }

    &.noAdvancedSearch {
      .ta-row {
        max-width: 100%;
      }

      .ta-filter {
        &--title {
          font-size: 16px;
        }

        &--range {
          border: none;
        }

        &--extra {
          position: relative;

          &__submit {
            width: 100%;
            max-width: 100%;
           

            button {
              position: relative;
              height: 72px;
              color: transparent;

              &:after {
                position: absolute;
                content: 'Search Land for Sale';
                display: block;
                width: 100%;
                color: color(green-dark);
                font-family: 'Lato', sans-serif;
                letter-spacing: 1.8px;
                top: 50%;
                left: 50%;
                font-size: 16px;
                font-weight: 700;
                transform: translate(-50%, -35%);
              }
            }
          }

          &__reset {
            position: absolute;
            left: 0;
            top: 90px;
            width: auto !important;
            font-size: 16px;
            color: #000;
            font-weight: 700;
            letter-spacing: 2px;
            text-decoration: none;
            margin-left: 16px;

            &::after {
              content: ' ';
              position: absolute;
              bottom: -10px;
              left: 0;
              width: 100%;
              height: 1px;
              background: #000;
            }
          }
        }
      }
    }

    &--range {
      overflow: visible;

      .advancedsearch & {
        &.ta-land,
        &.ta-frontage {
          display: none;
        }
      }
    }

    &--checklist {
      &.ta-other,
      &.ta-property {
        display: none;
      }

      .advancedsearch & {
        &.Other {
          display: none;
        }
      }
    }

    .isToggle {
      display: block;
    }

    .status-options {
      margin-top: -10px;
    }

    .ta-status {
      h3 {
        display: none;
      }

      .checklist span {
        &:first-child {
          display: none;
        }

        &:last-child {
          label {
            position: relative;
            color: transparent;
            border: none;
            background: none !important;
            opacity: 1;

            &:before {
              content: ' ';
              position: absolute;
              display: inline-block;
              width: 38px;
              height: 38px;
              border: 4px #fff solid;
              left: 0;
              top: 0;
              color: #fff;
              font-size: 30px;
              line-height: 46px;
              outline: 1px #000 solid;
              outline-offset: -2px;
            }

            &:after {
              position: absolute;
              left: 50px;
              top: 12px;
              content: 'Only include titled lots';
              white-space: nowrap;
              overflow: visible;
              display: inline-block;
              color: color(green-dark);
              width: 300px;
              font-family: $themeFontBold;
              font-size: 16px;
            }
          }

          input:checked {
            + label {
              &:before {
                background: color(green-dark);
              }
            }
          }
        }
      }
    }
  }
}

.ta-filter--extra .ta-filter--extra__submit .ta-btn::after {
  color: color(green-dark) !important;
  font-family: 'Lato', sans-serif;
  letter-spacing: 1.8px;
  font-size: 16px;
  font-weight: 700;
}