@import "~traffic-property-app/assets/scss/settings";

.package {
  transition: all 0.3s ease, opacity 0.1s ease;

  &:first-child {
    margin-top: 10px;
  }

  &:not(:first-child) {
    margin-top: 18px;
  }

  max-width: 450px;
  @include grid-column(12);
  @include grid-media($medium) {
    margin-top: 10px !important;
    @include grid-column(6);
  }

  @include grid-media($large) {
    @include grid-column(6);
  }

  @include grid-media($xlarge) {
    @include grid-column(4);
  }

  @include grid-media($xxlarge) {
    @include grid-column(4);
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    .image {
      opacity: 0.7;
    }
  }

  &.summary {
    @include grid-media($xxlarge) {
      @include grid-column(3);
    }

    background-color: color(grey4);
    // padding: 16px;
    padding-top: 0;

    a {
      text-decoration: none;
      color: inherit;

      .header {
        margin-bottom: 10px;

        @include grid-media($medium) {
          margin-bottom: 20px;
        }

        .image {
          height: 100px;
          background: white;
          transition: opacity 0.35s ease;
          @include grid-column(4);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .title {
          @include grid-column(8);
          margin-top: 10px;
          padding-bottom: 0;
          font-size: $font-size-lg;
          line-height: $font-size-lg;
          font-family: $appFontBold;
          margin-top: 18px;
          //
          // @include grid-media($xxlarge) {
          //   font-size: 12px;
          //   line-height: 30px;
          // }

          h1:nth-child(2):before {
            height: 36px;
          }

          // font-size: 10px;
          // line-height: 20px;
          //
          // h1:nth-child(2):before {
          //   height: 28px;
          // }

          // @include grid-media($xlarge) {
          //   font-size: 12px;
          //   line-height: 30px;
          //
          //   h1:nth-child(2):before {
          //     height: 36px;
          //   }
          // }
        }
      }

      .specs {
        display: block;
        padding: 12px 16px 7px;
        margin-bottom: 10px;
        background: color(white);
        color: color(black);
        @include grid-column(12);

        // &:after {
        //   content: " ";
        //   clear: both;
        //   display: block;
        // }
      }
    }
  }

  a.actionButton {
    padding-right: 16px;

    &:last-child {
      padding-right: 28px;
      @include grid-media($medium) {
        padding-right: 35px;
      }
    }

    &:after {
      content: " ";
      background-image: url(~traffic-property-app/assets/icons/others/chevron.svg);
      background-repeat: no-repeat;
      background-size: 100%;
      transform: rotate(270deg) translateY(0);
      position: absolute;
      width: 13px;
      height: 9px;
      margin-left: 4px;
      margin-top: 2px;
      transition: 0.2s ease all;
      // @include grid-media($medium) {
      //   margin-top: 2px;
      // }
    }

    &:hover {
      &:after {
        transform: rotate(270deg) translateY(3px);
      }
    }
  }

  .actionButton {
    position: relative;
    padding: 12px 0;
    @include grid-column(6);

    font-size: 12px;

    // @include grid-media($medium) {
    //   font-size: 13px;
    // }
    @include grid-media($xxlarge) {
      font-size: 14px;
    }

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }

    .emphasize {
      font-family: $themeFontMedium;
      color: color(orange);
      border: 1px solid color(orange);
      padding: 3px;
      margin-right: 7px;

      :global(.ta-icon) {
        display: inline-block;
        vertical-align: middle;
        height: 10px;
        width: 15px;
        margin: 0;
        background-repeat: no-repeat;
        background-size: auto 10px;
        background-position: center;
      }
    }

    &:hover .emphasize {
      background-color: color(black);
      transition: 0.3s ease all;

      :global(.ta-icon) {
        filter: invert(1);
      }
    }
  }
}
