@import "~traffic-property-app/assets/scss/settings";

.coordMap {
  position: relative;

  a {
    polygon {
      fill: rgba(color(available), 0.3);
      stroke: white;
      stroke-width: 2px;
      transition: all 0.3s ease;
      cursor: inherit;
    }
  }

  polygon {
    fill: transparent;
    stroke: white;
    stroke-width: 2px;
    &:not(.sold) {
      cursor: pointer;
      transition: all 0.3s ease;
    }

    &.available {
      fill: rgba(color(available), 0);
    }

    &.hold {
      // fill: rgba(247,178,4, 0.5);
      fill: rgba(color(hold), 0.5);
    }

    &.sold {
      // fill: rgba(247,4,4, 0.5);
      fill: rgba(color(sold), 0.5);
    }

    &.builder {
      fill: rgba(color(builders), 0.3);
      &:hover {
        stroke: color(builders);
        fill: rgba(color(builders), 0.85);
      }
    }
  }

  svg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: auto;
    z-index: 1;
  }
}

.zoom {
  position: absolute;
  z-index: 5;
  right: 16px;
  top: 16px;

  button {
    border: none;
    box-shadow: none;
    color: color(black);
    background: color(white);
    outline: none;
    display: block;
    width: 30px;
    padding: 0;
    height: 30px;
    margin-bottom: 1px;
    cursor: pointer;
    font-size: 30px;
    transition: 0.1s ease all;

    &:disabled {
      color: color(grey2);
      opacity: 0.6;
      cursor: auto;
    }

    &:last-child {
      font-size: 30px;
      line-height: 0;
    }
  }
}
