@import "~traffic-property-app/assets/scss/settings";

.select {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  padding: 0px 10px 0;
  height: 38px;
  width: 135px;
  background: color(white);
  border: 1px color(black) solid;
  text-transform: uppercase;
  font-family: $themeFontMedium;
  font-size: 13px;
  letter-spacing: 1.14px;
  line-height: 15px;
  cursor: pointer;
  transition: border 0.3s ease;

  @include grid-media($xlarge) {
    width: 120px;
  }

  @include grid-media($xxlarge) {
    height: 48px;
    padding: 4px 10px 0;
    font-size: 16px;
    width: 160px;

    .ta-has-results & {
      padding: 0px 10px 0;
      height: 38px;
      font-size: 13px;
      width: 135px;
      line-height: 15px;
    }

    .other-options &,
    .advancedsearch & {
      height: 38px;
      padding-top: 0;
      font-size: 14px;
      font-family: $themeFontBook;
      
      &:after {
        top: 8px;
      }

      select {
        height: 38px;
      }
    }
  }

  &--divider {
    display: inline-block;
    width: 16px;
    height: 3px;
    background: color(black);
    vertical-align: middle;
    margin: 0 10px;

    @include grid-media($xlarge) {
      width: 12px;
      margin: 0 5px;
    }

    @include grid-media($xxlarge) {
      width: 16px;
      margin: 0 10px;
    }
  }

  &.builder {
    width: 230px;
  }

  &:hover {
    border-color: color(buttonPrimary);
  }

  &:after {
    content: " ";
    z-index: 0;
    position: absolute;
    top: 10px;
    right: 8px;
    width: 14px;
    height: 14px;
    transform: rotate(-90deg);
    
    background: transparent url(~traffic-property-app/assets/icons/others/chevron.svg) no-repeat center
      center;
      background-size: contain;

    @include grid-media($xxlarge) {
      top: 17px;

      .ta-has-results & {
        top: 8px;
      }
    }
  }

  select {
    position: relative;
    z-index: 1;
    display: inline-block;
    border: none;
    height: 38px;
    padding: 0;
    background: transparent;
    box-shadow: none;
    outline: none;
    border-radius: 0;
    width: calc(100% + 25px);
    cursor: pointer;
    -webkit-appearance: none;
    // text-transform: uppercase;

    @include grid-media($xxlarge) {
      height: 42px;
    }
  }

  &.single {
    width: 230px;
  }
}
