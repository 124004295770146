@import '~assets/scss/settings';

.video {
  width: 100%;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.screen {
  background-size: cover;
  padding-bottom: 56%;
  position: relative;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;

  .inner {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.play {
  width: 80px;
  height: 80px;
}

.content {
  display: flex;
  align-items: flex-end;

  h3 {
    margin-bottom: 0 !important;
    line-height: 1;
    margin-right: 10px;
  }
}
