@import "~traffic-property-app/assets/scss/settings";

a.ta-btn {
  padding-top: 20px;
}

.ta-btn {
  display: block;
  width: 100%;
  height: 56px;
  margin: 0 auto;
  font-family: $themeFontMedium;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 15px;
  border: 1px solid color(buttonPrimary);
  background-color: color(buttonPrimary);
  color: color(white);
  padding: 8px 20px 0;
  text-transform: uppercase;
  box-shadow: none;
  outline: 0;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: color(white);
    color: color(buttonPrimary);
  }

  &[disabled] {
    &,
    &:hover {
      border: 1px solid color(buttonPrimary);
      background-color: color(buttonPrimary);
      color: color(white);
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  @include grid-media($large) {
    max-width: 210px;
  }

  &__secondary {
    background-color: transparent;
    border-color: color(grey5);
    color: color(grey5);

    &:hover {
      background-color: color(grey5);
      color: color(white);
    }
  }

  &__large {
    font-size: 14px;
    padding: 2px 50px 0;

    @include grid-media($large) {
      padding-top: 4px;
      font-size: 18px;
    }
  }

  &__medium {
    font-size: 14px;
    height: 48px;

    @include grid-media($large) {
      padding-top: 0;
    }
  }

  &__small {
    font-size: 14px;
    padding: 2px 10px 0;
    height: 40px;
    line-height: 0;
  }
}
